import React from 'react'
import services from '../../assets/services.png';
import supports from '../../assets/supports.png';
import team from '../../assets/team-members.png';
import { NavLink } from 'react-router-dom';

function AboutUs() {
  return (
    <div>
      <div className="flex items-center min-h-0 justify-center bg-gradient-to-r from-blue-400  to-purple-600">
        <div className="text-center py-8 md:py-10">
          <h1 className="text-neutral-50 text-3xl md:text-[40px] md:p-[24px]">
            Welcome to DigiSoulTech
          </h1>
          <p className="md:text-xl text-white text-lg md:p-[24px] ">
            Unveiling the Future of Technology, Expert Insights and Innovations
            Our Mission Help Business Growth
          </p>
          <button className=" bg-slate-800 text-slate-200 font-sans rounded-md mb-5 font-bold w-40 h-12 text-lg">
            <NavLink to="/services">Explore</NavLink>
          </button>
        </div>
      </div>
      <div className=" text-3xl font-bold text-gray-900 text-center py-8 underline ">
        Small Description About Us
      </div>
      <div className=" text-lg text-slate-900 text-center ">
        DigiSoul Tech Pvt Ltd. is a leading IT solution company specialized in
        providing a wide range of services including IT Consulting, IT Support
        and Maintenance, Software Development, Mobile Application Development,
        Website Development, Cloud Computing, Cybersecurity and many more. We
        maintain a strong reputation for delivering innovative solutions that
        help businesses to stay ahead of the curve. We not only provide
        businesses with cutting edge solutions and vast network of ingenious
        experts but also provide a top-notch customer service to our clients
        that makes us a reliable and trusted partners for businesses of all
        sizes. DigiSoul Tech Pvt Ltd. is playing a crucial role in helping the
        businesses to stay competitive in today’s fast paced world by providing
        support and solution to the business and meeting their technological
        needs. DigiSoul Tech is the best company that businesses can rely upon
        when it comes to IT Solutions.
      </div>
      <div className="flex flex-wrap py-10 -mx-4 gap-20 ml-56 ">
        {/* Box 1 */}
        <div className="md:w-64 w-64 px-4 md:mb-8   bg-white border-2 border-slate-500 text-center">
          <img
            src={services}
            alt="logo"
            className="inline w-24 md:w-24 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
          />
          <div className=" font-bold text-2xl text-slate-900 ">
            Quality Services
          </div>
          <div className="text-lg text-slate-600">
            we provide best quality services in web and software solutions at
            best price to the clients.
          </div>
        </div>
        {/* Box 2 */}
        <div className="md:w-64 w-72 px-4 md:mb-8 bg-white border-2 border-slate-500  text-center">
          <img
            src={supports}
            alt="logo"
            className="inline w-24 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
          />
          <div className=" font-bold text-2xl text-slate-900">Good Support</div>
          <div className="text-lg text-slate-600">
            we provide good support in maintenance and development to clients in
            complete IT solutions.
          </div>
        </div>
        <div className="md:w-64 w-72 px-4 mb-8 bg-white border-2 border-slate-500  text-center">
          <img
            src={team}
            alt="logo"
            className="inline w-24 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
          />
          <div className=" font-bold text-2xl text-slate-900 ">
            Teams Member
          </div>
          <div className="text-lg text-slate-600">
            They have the best teams which work accurately and efficiently.
          </div>
        </div>
      </div>
      <div className=" bg-slate-300 mr-5 md:ml-20 md:mr-96 shadow-2xl rounded-lg md:p-4">
        <h4 className=" text-4xl text-gray-900 text-center p-4">
          Salient Features
        </h4>
        <p className=" text-lg text-center p-4">
          {" "}
          Personalised Customer Experience:- Customer satisfaction is not our
          target, it is our habit. We ensure that the customers are not only
          served with top quality solutions but also with excellent service in
          terms of hospitality and behaviour of our employees towards our
          clients. We treat every client with respect. Our experts deal with
          client when it comes to analysing their problems. Our team structures
          a customized solution for every customer. We are committed to provide
          our clients with unique and innovative solutions.
        </p>
      </div>
      <div className=" bg-zinc-300 opacity-100 ml-5 mr-5 md:ml-96 md:mr-20 shadow-2xl scroll-ps-6  mt-20 rounded-lg p-6 ">
        <h4 className=" text-4xl text-gray-900 opacity-100 text-center">
          Expertise and Experience
        </h4>
        <p className=" text-lg text-center p-4">
          Personalised Customer Experience:- We provide a vast network of
          experienced experts. We have experts from different fields who are
          eager to explore new fields and problems. They update themselves
          everyday with the knowledge of new technologies coming up. Our experts
          not only use experience to solve the problem but also suggest the
          latest and modified versions of technology that businesses can use to
          meet their technological requirements.
        </p>
      </div>
      <div className=" bg-violet-300 opacity-100 shadow-6xl scroll-ps-6 ml-5 mr-5 md:ml-36 md:mr-72 mt-20 mb-9 rounded-lg p-6">
        <h4 className=" text-4xl text-gray-900 text-center">
          Personalised Customer Experience
        </h4>
        <p className=" text-lg text-center p-4">
          Technology is a thing to explore and learn. Since we provide solutions
          that can work with the latest technology, we make our clients also
          familiar with the product and latest technology as they are going to
          use them in future. We provide training to the clients so that they
          can access and use the products and latest technology smoothly without
          any hesitation and can walk along with this era of innovation. Because
          we believe that people of every generation should enjoy learning
          technology.
        </p>
      </div>
    </div>
  );
}
 export default AboutUs;

 
