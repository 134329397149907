import React from 'react'
import { VscCircleFilled } from "react-icons/vsc";
import certifyicon from "../../assets/certify.png";

import knowledge from "../../assets/knowledge.png";
import CIH  from "../../assets/CIH.png"
import ECCouncilengage from "../pages/ECCouncilengage";

const IconText = ({ text, hasIcon = true }) => (
  <div className="flex items-center mb-2">
    {hasIcon && <VscCircleFilled />}
    <h3 className={hasIcon ? "ml-2" : ""}>{text}</h3>
  </div>
);

const Section = ({title, items }) => (
  <div className=" ml-48 mb-10">
    
    {title && <h3 className="font-bold text-xl p-4">{title}</h3>}
    <div>
      {items.map((item, index) => (
        <IconText key={index} text={item} hasIcon={!item.startsWith("-")} />
      ))}
    </div>
  </div>
);

function ECCouncilcertification() {
  return (
    <div>
      <img src={certifyicon} alt="banner" className=" w-72 h-44 p-9" />
      <h1 className="text-2xl font-bold ml-10">
        Prove Your Skills and Abilities With
        <br /> Online, Practical Examinations
      </h1>
      <hr className="border-black w-96 mt-5 ml-9" />
      <p className="p-10 text-lg">
        The Certified Ethical Hacker® credential is trusted globally as the
        industry standard for evaluating one’s understanding of ethical hacking
        and security testing. As an ANSI 17024 accredited examination, the
        150-question, 4-hour proctored exam is recognized across the globe as
        the original and most trusted tactical cyber security certification for
        ethical hackers. Certification domains are carefully vetted through
        industry practitioners, ensuring the certification maps to current
        industry requirements; this exam undergoes regular psychometric
        evaluation and tuning to ensure a fair and accurate measure of the
        candidate’s knowledge in the ethical hacking domain.
      </p>
      <div className="font-bold text-2xl flex p-6 md:p-10 gap-10  md:gap-32">
        <h3>Knowledge Exam </h3>
        <h1>+</h1>
        <h3>Skills Exam</h3>
      </div>
      <img src={knowledge} alt="banner" className=" md:ml-10 " />
      <img src={CIH} alt="banner" className=" md:w-6/12 md:ml-80 p-10" />
      <div className=" md:ml-28 ml-10">
        <h1 className="font-bold text-3xl">
          Certified Ethical Hacker (C|EH®) Certification
        </h1>
        <p className="text-lg">
          The C|EH® exam is a 4-hour exam with 125 multiple-choice questions.
          This knowledge-based exam will test your skills in information
          security threats and attack vectors, attack detection, attack
          prevention, procedures, methodologies, and more! Access our Exam
          Blueprint for C|EH®
        </p>
        <button className=" bg-red-600 text-lg p-2 mt-4 text-white">
          Download Now
        </button>
      </div>
      <div className="md:ml-28 ml-10 ">
        <h2 className="font-bold text-3xl  mt-9">
          C|EH® Practical Certification
        </h2>
        <p className="text-lg ">
          The C|EH® Practical is a 6-hour, 100% hands-on exam delivered in our
          Cyber Range that requires you to demonstrate skills and abilities of
          ethical hacking techniques such as:
        </p>
        <Section
          items={[
            " Port scanning tools (e.g., Nmap, Hping)",
            "Vulnerability detection",
            "Attacks on a system (e.g., DoS, DDoS, session hijacking, web server and web application attacks, SQL injection, wireless threats)",
            "SQL injection methodology and evasion techniques",
            "Web application security tools (e.g., Acunetix WVS)",
            "SQL injection detection tools (e.g., IBM Security AppScan)",
            "Communication protocols",
          ]}
        />
        <p className="text-lg">
          This is the next step to becoming a C|EH® Master after you have
          achieved your C|EH® certification. Within the C|EH® Practical, you
          have limited time to complete 20 challenges to test your skills and
          proficiency in a performance-based cyber range. This exam is NOT a
          simulation and incorporates a live corporate network of VMs and
          applications with solutions to uncover vulnerabilities.
        </p>
        <h1 className="font-bold text-3xl  mt-9">C|EH® Master</h1>
        <p className="text-lg ">
          Upon completing the C|EH® (Master) program, consisting of the C|EH®
          and C|EH® (Practical), the C|EH® (Master) designation is awarded.
          C|EH® Masters have shown proficiency at a master level in the
          knowledge, skills, and abilities of ethical hacking with a total of 6
          hours of testing to prove their competency. The top 10 performers in
          both C|EH® and C|EH® Practical exams are featured on the C|EH® Master
          Global Ethical Hacking Leader Board.
        </p>
      </div>
      <h1 className="text-2xl font-bold ml-10 mt-9">
        The C|EH® Exam at a Glance
      </h1>
      <div className="overflow-x-auto md:p-10">
        <table className="table-auto md:w-full border-collapse border-2 border-red-500 text-xl">
          <thead>
            <tr className="bg-gray-200">
              <th className="border-2 border-red-500 p-4 font-bold">
                Exam Details
              </th>
              <th className="border-2 border-red-500 p-4 font-bold">
                C|EH® (MCQ Exam)
              </th>
              <th className="border-2 border-red-500 p-4 font-bold">
                C|EH® (Practical)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-2 border-red-500 p-4">
                Number of Questions/Practical Challenges
              </td>
              <td className="border-2 border-red-500 p-4">125</td>
              <td className="border-2 border-red-500 p-4">20</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Test Duration</td>
              <td className="border-2 border-red-500 p-4">4 Hours</td>
              <td className="border-2 border-red-500 p-4">6 Hours</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Test Format</td>
              <td className="border-2 border-red-500 p-4">
                Multiple Choice Questions
              </td>
              <td className="border-2 border-red-500 p-4">iLabs Cyber Range</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Test Delivery</td>
              <td className="border-2 border-red-500 p-4">ECC EXAM, VUE</td>
              <td className="border-2 border-red-500 p-4">-</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Availability</td>
              <td className="border-2 border-red-500 p-4">-</td>
              <td className="border-2 border-red-500 p-4">Aspen-iLabs</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Exam Prefix</td>
              <td className="border-2 border-red-500 p-4">
                312-50 (ECC EXAM), 312-50 (VUE)
              </td>
              <td className="border-2 border-red-500 p-4">-</td>
            </tr>
            <tr>
              <td className="border-2 border-red-500 p-4">Passing Score</td>
              <td className="border-2 border-red-500 p-4">
                Refer to https://cert.eccouncil.org/faq.html
              </td>
              <td className="border-2 border-red-500 p-4">70%</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ECCouncilengage />
    </div>
  );
}

export default ECCouncilcertification;
