import React from 'react'
import completeicon from "../../assets/complete.png";
import { VscCircleFilled } from "react-icons/vsc";

const IconText = ({ text, hasIcon = true }) => (
  <div className="flex items-center mb-2">
    {hasIcon && <VscCircleFilled />}
    <h3 className={hasIcon ? "ml-2" : ""}>{text}</h3>
  </div>
);

const Section = ({ title, items }) => (
  <div className=" ml-40 mb-10 ">
    {title && <h3 className="font-bold text-xl">{title}</h3>}
    <div>
      {items.map((item, index) => (
        <IconText key={index} text={item} hasIcon={!item.startsWith("-")} />
      ))}
    </div>
  </div>
);
function ECCouncilcomplete() {
  return (
    <div>
      <img src={completeicon} alt="banner" className="w-80 h-72 p-20 " />
      <h1 className="text-3xl font-bold  ml-24">
        Without a Stimulating Cyber Competition, There Can Be No Progress.
        Competitors Drive You to Be the Best You Can Be.
      </h1>
      <hr className="border-black w-12/12 mt-5 ml-24" />
      <p className="text-xl ml-24 mt-4">
        The C|EH® Global Challenges occur every month, providing
        capture-the-flag style competitions that give students exposure to
        various new technologies and platforms, from web applications, OT, IoT,
        SCADA, and ICS systems to cloud and hybrid environments. Our compete
        structure lets ethical hackers fight their way to the top of the
        leaderboard each month in these 4-hour curated CTFs. Objective-based
        flags are designed around the ethical hacking process, keeping skills
        current, testing critical thinking abilities, and covering the latest
        vulnerabilities and exploits as they are discovered. Hosted 100% online
        in EC-Council’s Cyber Range, candidates race the clock in scenario-based
        engagements against fully developed network and application environments
        with real operating systems, real networks, tools, and vulnerabilities
        to practice, engage, compete, build, and hone their cyber skills against
        various new target organizations.
      </p>
      <h1 className="text-3xl font-bold  ml-24 mt-6 mb-6">
        Key Updates of C|EH® v12
      </h1>
      <div className="md:flex">
        <Section
          title="Features:"
          items={[
            "New Learning Methodology: Learn – Certify – Engage – Compete",
            "Compete: new challenges every month to test your job-ready skills!",
            "100% Compliance to NICE 2.0 Framework",
            "Based on a comprehensive industry-wide job-task analysis",
            " Hands-on learning labs",
            "Practice Range",
            "Global C|EH community competitions",
            " Cheat Sheet",
            "Coverage of the latest malware",
            "Lab-intensive program (Every learning objective is demonstrated using labs)",
            " Hands-on program (More than 50% of training time is dedicated to labs)",
            "Lab environment simulates a real-time environment(Lab setup simulates real-life networks and platforms)",
            "Covers the latest hacking tools (Based on Windows, macOS, and Linux)",
            " Latest OS covered and a patched testing environment",
            "All the tool screenshots are replaced with the latest version",
            "All the tool listing slides are updated with the latest tools",
            "All the countermeasure slides are updated",
          ]}
        />
        <Section
          title="Technology Updates:"
          items={[
            "MITRE ATTACK Framework",
            " Diamond Model of Intrusion Analysis",
            "Techniques for Establishing Persistence",
            "Evading NAC and Endpoint Security",
            " Fog Computing",
            "Edge Computing",
            "Grid Computing",
          ]}
        />
      </div>
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-4 ml-3">Course Content</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">
              3000+ Student Manual Pages
            </h2>
          </div>
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">
              1900+ Lab Manual Pages
            </h2>
          </div>
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">
              3500+ Hacking & Security Tools
            </h2>
          </div>
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">
              220 Hands-On Lab Practicals
            </h2>
          </div>
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">
              519 Attack Techniques
            </h2>
          </div>
          <div className="p-4 border rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">20 Refreshed Modules</h2>
          </div>
        </div>
      </div>
      <h1 className="text-3xl font-bold mb-4 text-center p-10">
        Common Job Roles for C|EH
      </h1>
      <div className="container mx-auto px-4 md:flex">
        <Section
          items={[
            "Mid-Level Information Security Auditor",
            "Cybersecurity Auditor",
            "Security Administrator",
            "IT Security Administrator",
            "Cyber Defense Analyst",
            "Vulnerability Assessment Analyst",
            "Warning Analyst",
            "Information Security Analyst 1",
            "Security Analyst L1",
            "Infosec Security Administrator",
          ]}
        />
        <Section
          items={[
            "Cybersecurity Analyst level 1, level 2, & level 3",
            "Network Security Engineer",
            "SOC Security Analyst",
            "Security Analyst",
            "Network Engineer",
            "Senior Security Consultant",
            "Information Security Manager",
            "Senior SOC Analyst",
            "Solution Architect",
            "Cybersecurity Consultant",
          ]}
        />
      </div>
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold mb-4">C|EH® v12 Exam Information</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-xl">
          <div className="p-4 border border-red-500 rounded-lg shadow-md ">
            <h1 className="text-2xl font-semibold mb-2">C|EH® (ANSI)</h1>
            <hr className="border-black w-96 mt-5" />
            <h3 className="font-semibold">Exam Title:</h3>
            <p>Certified Ethical Hacker (ANSI)</p>
            <h3 className="font-semibold">Exam Code:</h3>
            <p>312-50 (ECC EXAM), 312-50 (VUE)</p>
            <h3 className="font-semibold">Number of Questions:</h3>
            <p>125</p>
            <h3 className="font-semibold">Duration:</h3>
            <p>4 hours</p>
            <h3 className="font-semibold">Availability:</h3>
            <p>ECCEXAM/VUE</p>
            <h3 className="font-semibold">Test Format:</h3>
            <p>Multiple Choice</p>
            <h3 className="font-semibold">Passing Score:</h3>
            <p>
              Please refer to{" "}
              <a
                href="https://cert.eccouncil.org/physical-certificate-printing.html"
                className="text-blue-500"
              >
                https://cert.eccouncil.org/physical-certificate-printing.html
              </a>
            </p>
          </div>

          <div className="p-4 border border-red-500 rounded-lg shadow-md">
            <h1 className="text-2xl font-semibold mb-2">C|EH® PRACTICAL</h1>
            <hr className="border-black w-96 mt-5" />
            <h3 className="font-semibold">Exam Title:</h3>
            <p>Certified Ethical Hacker (Practical)</p>
            <h3 className="font-semibold">Number of Practical Challenges:</h3>
            <p>20</p>
            <h3 className="font-semibold">Duration:</h3>
            <p>6 hours</p>
            <h3 className="font-semibold">Availability:</h3>
            <p>ASPEN iLabs</p>
            <h3 className="font-semibold">Test Format:</h3>
            <p>iLabs cyber range</p>
            <h3 className="font-semibold">Passing Score:</h3>
            <p>70%</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-10 mr-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="p-4 bg-red-600 text-white rounded-lg shadow-md max-w-4xl">
            <h1 className="text-xl font-semibold mb-2">Training</h1>
            <h1 className="text-lg mb-2">3 Month</h1>
          </div>
          <div className="p-4 bg-red-600 text-white rounded-lg shadow-md">
            <h1 className="text-xl font-semibold mb-2">Duration</h1>
            <h1 className="text-lg mb-2">2 Hours(Week)</h1>
          </div>
        </div>
      </div>

      <h1 className="text-3xl font-bold ml-14 mt-6">Training Options</h1>
      <div className="container mx-auto px-4 py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="p-4 border border-red-600 rounded-lg">
            <h1 className="text-xl font-semibold mb-2">iLearn (Self-Study)</h1>
            <hr className="border-black w-8/12 p-4" />
            <p>
              This solution is an asynchronous, self-study environment in a
              video streaming format
            </p>
          </div>
          <div className="p-4 border border-red-600 rounded-lg">
            <h1 className="text-xl font-semibold mb-2">iWeek (Live Online)</h1>
            <hr className="border-black w-8/12 p-4" />
            <p>
              This solution is a live, online, instructor-led training course
            </p>
          </div>
          <div className="p-4 border border-red-600 rounded-lg">
            <h1 className="text-xl font-semibold mb-2">Master Class</h1>
            <hr className="border-black w-8/12 p-4" />
            <p>
              The opportunity to learn from world-class instructors and
              collaborate with top Infosecurity professionals.
            </p>
          </div>
          <div className="p-4 border border-red-600 rounded-lg">
            <h1 className="text-xl font-semibold mb-2">
              Training Partner (In Person)
            </h1>
            <hr className="border-black w-8/12 p-4" />
            <p>
              This solution offers “in-person” training so that you can get the
              benefit of collaborating with your peers and gaining real-world
              skills, conveniently located in your backyard.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECCouncilcomplete