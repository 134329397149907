// src/components/PaymentCard.js

import React, { useState } from "react";

const paymentMethods = [
  { name: "PhonePe", icon: "📱" },
  { name: "Google Pay", icon: "💳" },
  { name: "NetBanking", icon: "🏦" },
  { name: "Credit Card", icon: "💳" },
  { name: "PayPal", icon: "🅿️" },
  { name: "Bank Transfer", icon: "🏦" },
];

const PaymentCard = () => {
  const [selectedMethod, setSelectedMethod] = useState("");

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-4">Select Payment Method</h2>
      <div className="space-y-4">
        {paymentMethods.map((method) => (
          <button
            key={method.name}
            onClick={() => handleMethodChange(method.name)}
            className={`w-full py-2 px-4 rounded-lg flex items-center justify-start space-x-2 ${
              selectedMethod === method.name
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            <span>{method.icon}</span>
            <span>{method.name}</span>
          </button>
        ))}
      </div>
      <div className="mt-6">
        <p className="text-lg">
          Selected Method:{" "}
          <span className="font-bold">{selectedMethod || "None"}</span>
        </p>
      </div>
    </div>
  );
};

export default PaymentCard;
