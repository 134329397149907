import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Logo from "../assets/digilogo.png";

const ProductCard = ({ product, price, quantity, subtotal, onRemove }) => {
  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden mb-6 mt-8">
      <div className="flex justify-end p-2">
        <button
          onClick={onRemove}
          className="text-gray-500 hover:text-gray-700"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex items-center p-4">
        <img
          className="h-24 w-24 object-cover rounded-full"
          src={product.image}
          alt={product.name}
        />
      </div>
      <div className="px-4 py-2">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200">Product</th>
              <th className="py-2 px-4 border-b border-gray-200">Price</th>
              <th className="py-2 px-4 border-b border-gray-200">Quantity</th>
              <th className="py-2 px-4 border-b border-gray-200">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b border-gray-200">
                {product.name}
              </td>
              <td className="py-2 px-4 border-b border-gray-200">{price}</td>
              <td className="py-2 px-4 border-b border-gray-200">{quantity}</td>
              <td className="py-2 px-4 border-b border-gray-200">{subtotal}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SummaryCard = ({ subtotal, gst, navigate }) => {
  const total = subtotal + gst;

  const handleCheckout = () => {
    navigate("/paymentcomponent");
  };

  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-8 p-2">
      <div className="px-4 py-2">
        <h2 className="text-lg font-semibold text-gray-700">Carts Total</h2>
        <table className="min-w-full bg-white mt-4">
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b border-gray-200 font-medium">
                Subtotal
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {subtotal.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-200 font-medium">
                GST
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {gst.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-200 font-medium">
                Total
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {total.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <button
          onClick={handleCheckout}
          className="inline-flex w-full justify-center rounded-lg bg-red-600 px-5 py-2.5 mt-4 text-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-200 dark:focus:ring-red-900"
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

const CardsDetails = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/cardsdetails");
  };

  const [products, setProducts] = useState([
    {
      id: 1,
      name: "Sample Product",
      image: Logo,
      price: 20,
      quantity: 2,
      subtotal: 40,
    },
    // Add more products as needed
  ]);

  const handleRemove = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  return (
    <div className="p-10">
      <div className="flex justify-between items-center mb-6 shadow-lg bg-slate-200 p-5">
        <h3 className="text-lg ml-5">
          “The Cyber Combat Bundle” has been added to your cart.
        </h3>
        <button
          type="button"
          className="rounded-lg bg-red-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700"
          onClick={handleButtonClick}
        >
          Continue shopping
        </button>
      </div>

      <div className="flex">
        <ProductCard
          product={products[0]} // Assuming only one product for now
          price={products[0].price}
          quantity={products[0].quantity}
          subtotal={products[0].subtotal}
          onRemove={() => handleRemove(products[0].id)}
        />

        <SummaryCard subtotal={200} gst={36} navigate={navigate} />
      </div>

      {/* Add button to add more data */}
      <button
        type="button"
        className="rounded-lg bg-green-600 px-5 py-2.5 text-sm font-medium ml-72  text-white hover:bg-green-700 focus:outline-none focus:ring-4  mt-6"
        onClick={() => {
          setProducts([
            ...products,
            {
              id: products.length + 1,
              name: "New Product",
              image: Logo,
              price: 25,
              quantity: 1,
              subtotal: 25,
            },
          ]);
        }}
      >
        Add More Carts
      </button>
    </div>
  );
};

export default CardsDetails;
