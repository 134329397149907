import React, { useState } from 'react';
import { TextInput } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

function ECCouncilLogin() {
  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

 const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('http://localhost:5000/api/user/councillogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });

    if (response.ok) {
      // Login successful
      alert('Login Successful');
      setUser({ email: '', password: '' });
      navigate('/');
    } else {
      // Login failed
      alert('Login failed');
      console.log('Invalid credentials');
    }
  } catch (error) {
    // Error during fetch request
    console.error('Error during login:', error);
    alert('An error occurred during login');
  }
};

  return (
    <div>
      <div className="bg-white brightness-100 shadow-xl rounded-2xl p-4 text-center ml-96 mt-8 mb-6">
        <form className="max-w-lg flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="text-2xl text-indigo-950 font-bold">LOGIN</div>
          <div>
            <TextInput
              id="email"
              name="email"
              type="email"
              placeholder="amrita@gmail.com"
              required
              shadow
              value={user.email}
              onChange={handleInput}
            />
          </div>
          <div>
            <TextInput
              id="password"
              name="password"
              type="password"
              placeholder="password"
              required
              shadow
              value={user.password}
              onChange={handleInput}
            />
          </div>
          <button className="bg-pink-500 cursor-pointer text-white mt-3 rounded-xl font-bold h-12 text-lg" type="submit">
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}

export default ECCouncilLogin;











