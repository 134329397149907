import React from "react";
import Marquee from "react-fast-marquee";

import billpayment from "../../assets/billpayment.jpg";
import Educations from "../../assets/Educations.jpg";
import Ecommerce from "../../assets/Ecommerce.jpg";
import Medical from "../../assets/Medical.jpg";
import OnlineGrocery from "../../assets/Online-Grocery.jpg";
import Transports from "../../assets/Transports.jpg";

function AllServeIndustries() {
  return (
    <div className="shadow-xl  py-12  rounded-2xl">
      <div className=" text-4xl text-center text-black font-bold">
        Industries We Serve
      </div>
      <p className=" text-2xl text-center mt-3">
        We think big and have hands in all leading technology, for Website
        design & Development.
      </p>

      <div className=" ">
        <Marquee pauseOnHover={true} className="marquee-container mt-16  mb-6">
          <ImageWithText
            imgSrc={billpayment}
            imgAlt="Bill Payment Image"
            text="Bill Payment"
          />
          <ImageWithText
            imgSrc={Educations}
            imgAlt="Education Image"
            text="Education"
          />
          <ImageWithText
            imgSrc={Ecommerce}
            imgAlt="E-Commerce Image"
            text="E-Commerce"
          />
          <ImageWithText
            imgSrc={Medical}
            imgAlt="Medical Image"
            text="Medical"
          />
          <ImageWithText
            imgSrc={OnlineGrocery}
            imgAlt="Online Grocery Image"
            text="Online Grocery"
          />
          <ImageWithText
            imgSrc={Transports}
            imgAlt="Transports Image"
            text="Transports"
          />
        </Marquee>
      </div>
    </div>
  );
}

export default AllServeIndustries;

const ImageWithText = ({ imgSrc, imgAlt, text }) => {
  return (
    <div className="w-96 mx-5 flex flex-col space-y-5 text-center">
      <img
        src={imgSrc}
        alt={imgAlt}
        className="rounded-2xl w-96 h-56 object-cover"
      />
      <span className="text-pink-600 text-2xl  font-bold">{text}</span>
    </div>
  );
};
