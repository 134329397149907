import React, { useState } from "react";
import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const paymentMethods = [
  { name: "PhonePe", icon: "📱" },
  { name: "Google Pay", icon: "💳" },
  { name: "NetBanking", icon: "🏦" },
  { name: "Credit Card", icon: "💳" },
  { name: "PayPal", icon: "🅿️" },
  { name: "Bank Transfer", icon: "🏦" },
];

function Cards() {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState("");

  const handleButtonClick = () => {
    navigate("/cardsdetails");
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
  };

  return (
    <div className="p-20">
      <Card className="max-w-sm">
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
          Here’s Everything You’re Getting Today with Our Exclusive Bundle
        </h5>
        <div className="flex items-baseline text-gray-900 dark:text-white">
          <span className="text-3xl font-semibold font-sans mr-2 line-through">
            ₹28,618
          </span>
          <span className="text-5xl font-extrabold tracking-tight text-red-600">
            ₹4,919
          </span>
        </div>
        <h1 className="text-2xl tracking-tight">One-Time Payment</h1>
        <ul className="my-7 space-y-5">
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
              Access to 10 in-demand cybersecurity courses
            </span>
          </li>
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
              2 free bonus courses
            </span>
          </li>
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
              45+ hours of premium practical learning
            </span>
          </li>
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500">
              Certificates of achievement after completing each course
            </span>
          </li>
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500">
              Year-long access to courses
            </span>
          </li>
          <li className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500">
              Access to content updates and premium support for 1 year
            </span>
          </li>
        </ul>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-4">Select Payment Method</h2>
          <div className="space-y-4">
            {paymentMethods.map((method) => (
              <button
                key={method.name}
                onClick={() => handleMethodChange(method.name)}
                className={`w-full py-2 px-4 rounded-lg flex items-center justify-start space-x-2 ${
                  selectedMethod === method.name
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                <span>{method.icon}</span>
                <span>{method.name}</span>
              </button>
            ))}
          </div>
        </div>

        <button
          onClick={handleButtonClick}
          className="mt-6 w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Proceed
        </button>
      </Card>
    </div>
  );
}

export default Cards;
