import React from 'react'
import { ImArrowRight } from "react-icons/im";

function CourseDes() {

  const internshipBenefits = (benefits) => {
    return benefits.map((benefit, index) => (
      <div className="flex items-center font-serif text-lg mb-2" key={index}>
        <ImArrowRight />
        <div className="ms-4">
          <h5>{benefit}</h5>
        </div>
      </div>
    ));
  };
  return (
    <div>
      <div className="relative">
        <div className="text-4xl font-serif md:ml-32 ml-20 p-10 text-gray-900 underline decoration-1 decoration-slate-900 underline-offset-1">
          Marketing Excutive{" "}
        </div>
        <div className="mt-7 bg-zinc-100 opacity-100 ml-24 mr-20 shadow-2xl scroll-ps-6 rounded-lg p-6">
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Position : 20
          </h6>
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Job Descrtiption:-
          </h6>
          <p className=" text-lg  text-ellipsis ml-32 ">
            The candidate should have the minimum 2-7 Years of Marketing
            Executive Experience in the IT Industry.
            <br /> Comprehensive knowledge of Marketing
          </p>
          <div className="ml-8 text-stone-900 mb-5 mt-5">
            {internshipBenefits([
              "Good understanding of market research techniques, dataanalysis and statistics methods",
              "Thorough knowledge of strategic planning principles an marketing best practices",
              "Proficient in MS Office and marketing software (e.g. CRM)",
              "Excellent communication and people skills",
              "Familiarity with social media and web analytics (e.g. WebTrends)",
              "Strong organizational and time-management abilities",
              "Creativity and commercial awareness",
              " BSc/BA /MBA in marketing, business administration or relevant discipline",
            ])}
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="text-4xl font-serif md:ml-32 ml-20 p-10 text-gray-900 underline decoration-1 decoration-slate-900 underline-offset-1">
          U/I Front-End Developer
        </div>
        <div className="mt-7 bg-zinc-100 opacity-100 ml-24 mr-20 shadow-2xl scroll-ps-6 rounded-lg p-6">
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Position : 10
          </h6>
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Job Descrtiption:-
          </h6>
          <p className=" text-lg  text-ellipsis ml-32 ">
            The candidate should have the minimum 2-7 Years of U/I development
            and Executive Experience in the IT Industry.
          </p>
          <div className="ml-8 text-stone-900 mb-5 mt-5">
            {internshipBenefits([
              "Strong understanding of modern web design principles and techniques, including responsive design and user experience (UX) best practices.",
              "Proficiency in front-end technologies such as HTML5, CSS3, JavaScript, and frameworks/libraries like React.js or Angular.",
              "Experience with UI/UX design tools like Adobe XD, Sketch, or Figma",
              "Knowledge of web development tools and workflows, including version control (e.g., Git) and build tools (e.g., Webpack).",
              "Familiarity with back-end technologies and concepts to collaborate effectively with back-end developers.",
              "Excellent problem-solving skills and attention to detail",
              "Ability to work collaboratively in a team environment and communicate effectively with stakeholders.",
              "Strong organizational and time-management abilities to handle multiple projects simultaneously",
              "Continuous learning mindset to keep up with the latest trends and technologies in front-end development.",
              "Bachelor’s degree in Computer Science, Web Development, or related field preferred",
            ])}
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="text-4xl font-serif md:ml-32 ml-20 p-10 text-gray-900 underline decoration-1 decoration-slate-900 underline-offset-1">
          Senior .Net Web Developer
        </div>
        <div className="mt-7 bg-zinc-100 opacity-100 ml-24 mr-20 shadow-2xl scroll-ps-6 rounded-lg p-6">
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Position : 5
          </h6>
          <h6 className="text-lg text-slate-900 ml-5 font-serif">
            Job Descrtiption:-
          </h6>
          <p className=" text-lg  text-ellipsis ml-32 ">
            The candidate should have the minimum 2-7 Years of .Net Web
            Development Experience in the IT Industry.
          </p>
          <div className="ml-8 text-stone-900 mb-5 mt-5">
            {internshipBenefits([
              "Extensive experience with .NET framework and .NET Core, including ASP.NET MVC and Web API.",
              "Proficiency in C# programming language and object-oriented programming (OOP) principles.",
              "Familiarity with software development best practices, including agile methodologies, test-driven development (TDD), and continuous integration/continuous deployment (CI/CD)",
              "Excellent problem-solving skills and the ability to debug and troubleshoot complex issues.",
              "Ability to lead and mentor junior developers, providing guidance and support in their professional growth",
              "Bachelor’s or Master’s degree in Computer Science, Software Engineering, or a related field preferred.",
            ])}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDes