import React, { useEffect, useState } from 'react';
import { Button } from 'flowbite-react';
import { NavLink } from 'react-router-dom';


function AdminECCouncil() {

   const [users, setUsers] = useState([]);
 // const { authorizationToken } = useAuth();

  
    const getAllCouncilUsers = async () => {
      try {
        const response = await fetch("http://localhost:5000/api/admin/eccounciluserdata", {
          method: 'GET',
          headers: {
          //  Authorization: authorizationToken,
          },
        });
        const data = await response.json();
        console.log(`user ${data}`);
        setUsers(data);
      } catch (error) {
        console.log(error);
      }
    };

     //  delete the user on delete button
const deleteCouncilById = async (id) => {
  try {
    const response = await fetch(`http://localhost:5000/api/admin/users/delete/${id}`, {
      method: 'DELETE',
      headers: {
       // Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to delete user. Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(`User deleted successfully. Response: ${data}`);

    if(response.ok){
      getAllCouncilUsers();
    }
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

useEffect(() => {
    getAllCouncilUsers();
  }, []); // Added authorizationToken to the dependencies array

 
  return(
   <div>
  <div className='font-bold text-4xl text-slate-700 text-center text underline '>Admin EC-Council User Data</div>
  
  <div className="overflow-x-auto">
  <table className="w-full bg-blue-50 border-collapse mt-10">
    <thead>
      <tr>
        <th className="border border-blue-300 py-2">FirstName</th>
        <th className="border border-blue-300 py-2">LastName</th>
        <th className="border border-blue-300 py-2">Email</th>
        <th className="border border-blue-300 py-2">Phone</th>
        <th className="border border-blue-300 py-2">Qualification</th>
        <th className="border border-blue-300 py-2">Applyfor</th>
        <th className="border border-blue-300 py-2">Update</th>
        <th className="border border-blue-300 py-2">Delete</th>
      </tr>
    </thead>
    <tbody>
      {users.map((curUser, index) => (
        <tr key={index} className="text-center">
        
          <td className="border border-blue-300 py-2">{curUser.firstname}</td>
          <td className="border border-blue-300 py-2">{curUser.lastname}</td>
          <td className="border border-blue-300 py-2">{curUser.email}</td>
          <td className="border border-blue-300 py-2">{curUser.phone}</td>
          <td className="border border-blue-300 py-2">{curUser.qualification}</td>
          <td className="border border-blue-300 py-2">{curUser.applyfor}</td>
          <td className="border border-blue-300 py-2">
          <Button className='bg-red-500  text-white px-1 py-1 rounded'>
                <NavLink to= {`/alladmin/allstudentdetails/${curUser._id}/update`}>Update </NavLink>
         </Button>
          </td>
          <td className="border border-blue-300 py-2">
            <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={() => deleteCouncilById(curUser._id)}>
              Delete
            </button>
            
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
</div>
  )
}

export default AdminECCouncil;