import React, { useRef, useState } from "react";
import { Textarea, TextInput, FileInput, Label, Select } from "flowbite-react";
import emailjs from "@emailjs/browser";
import bcabanner from "../../assets/bca.jpg";
import mcabanner from "../../assets/Mca.jpg";
import bbabanner from "../../assets/BBA.jpg";
import mbabanner from "../../assets/MBA.jpg";

const Internship = () => {
  const form = useRef();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [selectedInternship, setSelectedInternship] = useState(null);

  // Function to handle opening the payment modal and setting selected internship
  const handleApplyNow = (internship) => {
    setSelectedInternship(internship);
    // Open your payment modal here
    // For simplicity, let's console log selected internship details
    console.log("Selected Internship:", internship);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Disable the submit button
    setButtonDisabled(true);

    // Collect form data
    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData.entries());

    emailjs
      .send("service_fonuseb", "template_utptydh", data, "cR_1YLLUsGKae2N4y")
      .then(
        () => {
          console.log("SUCCESS!");
          setTimeout(() => {
            setButtonDisabled(false);
          }, 20000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setTimeout(() => {
            setButtonDisabled(false);
          }, 20000);
        }
      );
  };

  const internshipData = [
    {
      image: bcabanner,
      alt: "BCA",
      title: "BCA Internship",
      duration: "6 months",
      importance:
        "Gain hands-on experience in computer applications and develop key skills in programming, software development, and database management.",
    },
    {
      image: mcabanner,
      alt: "MCA",
      title: "MCA Internship",
      duration: "6 months",
      importance:
        "Enhance your expertise in advanced computing concepts, software engineering, and data analysis with our comprehensive MCA internship program.",
    },
    {
      image: bbabanner,
      alt: "BBA",
      title: "BBA Internship",
      duration: "6 months",
      importance:
        "Learn essential business management skills, marketing strategies, and organizational behavior in a practical environment tailored for BBA students.",
    },
    {
      image: mbabanner,
      alt: "MBA",
      title: "MBA Internship",
      duration: "6 months",
      importance:
        "Develop leadership qualities, strategic thinking, and advanced business acumen through real-world projects and professional mentorship.",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-200">
      <div className="max-w-5xl mx-auto px-4 sm:px-4 lg:px-8 py-2">
        <div className="text-center mt-8">
          <h2 className="text-4xl font-bold text-gray-800 mb-7 p-4 animate-bounce">
            Join Our Internship Program!
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed animate-slide-in">
            Are you a BCA, MCA, MBA, or BBA student looking for a transformative
            internship experience? At Digisoultech Pvt Ltd, we offer a unique
            opportunity to gain hands-on experience, develop your skills, and
            grow your career in a supportive and innovative environment.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-14 mt-12 animate-fade-in">
          {internshipData.map((internship, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img
                src={internship.image}
                alt={internship.alt}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {internship.title}
                </h3>
                <p className="text-gray-700 mb-4">{internship.importance}</p>
                <div className="flex justify-between items-center">
                  <span className="text-gray-700 font-semibold">
                    {internship.duration}
                  </span>
                  <button
                    onClick={() => handleApplyNow(internship)} // Pass the internship object to handleApplyNow
                    className="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-500 transition duration-200"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Payment Modal Section */}
      {selectedInternship && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <div className="flex items-center">
              {/* Logo or Icon */}
              <div className="mr-4">
                <img
                  src={selectedInternship.image}
                  alt={selectedInternship.alt}
                  className="w-20 h-12 object-contain"
                />
              </div>
              {/* Amount */}
              <div>
                <p className="text-lg font-bold text-gray-800">Amount: 5000</p>
                <p className="text-sm text-gray-600">: Internship</p>
              </div>
            </div>
            {/* Payment Method Options */}
            <div className="mt-4">
              <p className="text-gray-700 font-semibold mb-2">
                Select Payment Method:
              </p>
              {/* Add your payment method options here */}
              <div>
                {/* Example Payment Method Option */}
                <input
                  type="radio"
                  id="paypal"
                  name="paymentMethod"
                  value="paypal"
                />
                <label htmlFor="paypal" className="ml-2">
                  PayPal
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="creditCard"
                  name="paymentMethod"
                  value="creditCard"
                />
                <label htmlFor="creditCard" className="ml-2">
                  Credit Card
                </label>
              </div>
              {/* Add more payment options as needed */}
            </div>
            {/* Close Modal Button */}
            <button
              className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-500 transition duration-200"
              onClick={() => setSelectedInternship(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="bg-white rounded-6xl p-8 mt-8 shadow-md ">
        <form
          className="max-w-xl mx-auto grid grid-cols-2 gap-4"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="text-2xl text-indigo-950 font-bold animate-bounce col-span-2">
            Get in touch for Internships
          </div>
          <div className="col-span-1 grid grid-cols-1 gap-3 animate-slide-in">
            <div>
              <Label htmlFor="fullname" value="Full Name" />
              <TextInput
                id="fullname"
                name="fullname"
                type="text"
                placeholder="Full Name"
                required
                shadow
              />
            </div>
            <div>
              <Label htmlFor="fathername" value="Father Name" />
              <TextInput
                id="fathername"
                name="fathername"
                type="text"
                placeholder="Father Name"
                required
                shadow
              />
            </div>
            <div>
              <Label htmlFor="phone" value="Mobile Number" />
              <TextInput
                id="phone"
                name="phone"
                type="number"
                placeholder="Mobile Number"
                required
                shadow
              />
            </div>
            <div>
              <Label htmlFor="email" value="Email ID" />
              <TextInput
                id="email"
                name="email"
                type="email"
                placeholder="digisoultech@gmail.com"
                required
                shadow
              />
            </div>
          </div>
          <div className="col-span-1 grid grid-cols-1 gap-3 animate-slide-in">
            <div>
              <Label htmlFor="collagename" value="College Name" />
              <TextInput
                id="collagename"
                name="collagename"
                type="text"
                placeholder="College Name"
                required
                shadow
              />
            </div>
            <div>
              <Label htmlFor="studentid" value="Student ID" />
              <TextInput
                id="studentid"
                name="studentid"
                type="text"
                placeholder="Student ID"
                required
                shadow
              />
            </div>
            <div>
              <Label
                htmlFor="qualification"
                value="Select Your Qualification"
              />
              <Select id="qualification" required name="qualification">
                <option>MCA</option>
                <option>Btech</option>
                <option>BCA</option>
                <option>MBA</option>
                <option>BBA</option>
                <option>Others</option>
              </Select>
            </div>
            <div>
              <Label htmlFor="duration" value="Select Internship Duration" />
              <Select id="duration" required name="duration">
                <option>30 Days</option>
                <option>45 Days</option>
                <option>60 Days</option>
                <option>90 Days</option>
                <option>180 Days</option>
              </Select>
            </div>
          </div>
          <div className="col-span-2 animate-slide-in">
            <Label htmlFor="address" value="Your Location" />
            <TextInput
              id="address"
              name="address"
              type="text"
              placeholder="Your Location"
              required
              shadow
            />
          </div>
          <div className="col-span-2 animate-slide-in">
            <Label htmlFor="message" value="Message" />
            <Textarea
              id="message"
              name="message"
              placeholder="Message"
              required
              rows={4}
            />
          </div>
          <div className="col-span-2 animate-slide-in">
            <Label
              htmlFor="file-upload-helper-text"
              value="Upload Your Resume (under 50Kb)"
            />
            <FileInput
              type="file"
              name="user_resume"
              accept=".pdf,.doc,.docx"
            />
          </div>
          <div className="col-span-2 animate-slide-in">
            <button
              type="submit"
              className="bg-pink-500 cursor-pointer text-white mt-3 rounded-md font-bold h-12 w-full text-lg"
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Internship;
