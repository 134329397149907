import React, { useState, useEffect } from "react";
import AllServeIndustries from "../dashboard/AllServeIndustries";
import { NavLink } from "react-router-dom";
import { HiChevronRight } from "react-icons/hi2";
import AllServices from "../dashboard/AllServices";

import Marquee from "react-fast-marquee";
import Typed from "react-typed";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
// import overlayImage from "../../assets/banner-person-1-1.png"

import bgbanner1 from "../../assets/9.jpg";
import bgbanner2 from "../../assets/5.jpg";
import bgbanner3 from "../../assets/bca.jpg";
import bgbanner4 from "../../assets/4.jpg";

import { PiArrowSquareUpRightBold } from "react-icons/pi";
import Marketing from "../../assets/Marketing.jpg";
import usersicon from "../../assets/usersicon.png";
import mobilebanner from "../../assets/mobile-banner.jpg";
import meeting from "../../assets/meeting.png";
import CSS from "../../assets/CSS.png";
import Figma from "../../assets/Figma.png";
import android from "../../assets/android.png";
import IOS from "../../assets/IOS.png";
import html from "../../assets/html-5_5968267.png";
import MySql from "../../assets/MySql.png";
import Python from "../../assets/Python.png";
import Jquery from "../../assets/Jquery.png";
import MongoDB from "../../assets/MongoDB.png";

const images = [bgbanner1, bgbanner2, bgbanner3, bgbanner4];

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const openSocialMedia = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="md:inline">
   <div className="relative w-full h-screen overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ${
            index === currentImageIndex ? "opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: index === currentImageIndex ? 0 : -1, // Ensure only the current image is on top
            filter: "brightness(100%)",
          }}
        />
      ))}
      <div
        className="absolute inset-0 flex items-center justify-center"
        style={{
          zIndex: 1, // Ensure overlay image is on top
        }}
      >
        {/* <img
          src={overlayImage}
          alt="Overlay"
          className="object-contain w-1/2 h-1/2"
        /> */}
      </div>
        <div className="absolute top-0 left-0 w-full h-full bg-opacity-50"></div>

        <div className="absolute top-40 md:top-10 bottom-7 text-white font-bold justify-center items-center text-center">
          <div className="text-xl md:text-5xl">
            <div className="text-[30px] md:text-[50px] md:p-[24px] mt-6 ml-32 text- text-opacity-100">
              Welcome to
              <Typed
                className="pl-3 md:text-5xl text-gray-950 font-bold"
                strings={["DigiSoulTech"]}
                typeSpeed={200}
                loop={true}
                backSpeed={200}
              />
            </div>
          </div>
          <div className="md:absolute text-red-950 md:mt-1 ml-8 mt-8 mdleft-5 flex flex-col sm:flex-row items-start">
            <div className="space-y-4 mr-3 md:mt-7 flex flex-col">
              <button
                onClick={() =>
                  openSocialMedia("https://www.facebook.com/digisoultechranchi")
                }
              >
                <BsFacebook className="text-3xl text-blue-700 hover:text-blue-300" />
              </button>
              <button
                onClick={() =>
                  openSocialMedia("https://www.instagram.com/digi_soultech/")
                }
              >
                <BsInstagram className="text-3xl text-pink-500 hover:text-pink-400" />
              </button>
              <button
                onClick={() =>
                  openSocialMedia(
                    "https://www.linkedin.com/company/90858382/admin/feed/posts/"
                  )
                }
              >
                <BsLinkedin className="text-3xl text-blue-700 hover:text-blue-300" />
              </button>
              <button
                onClick={() =>
                  openSocialMedia(
                    "https://www.youtube.com/channel/UCoGc4VoB5Op15i5YlSWAGUA"
                  )
                }
              >
                <BsYoutube className="text-3xl text-[#FF0000] hover:text-red-400" />
              </button>
            </div>

            <div className="text-xl md:text-4xl ml-2">
              <Typed
                className="text-2xl md:text-4xl text-blue-400"
                strings={[
                  "Our goal is to help you automate your process with a touch of your fingertips!",
                ]}
                typeSpeed={300}
                loop={true}
                backSpeed={300}
              />
            </div>
          </div>
          <div className="absolute ml-36 md:ml-72 md:bottom-20 text-white font-bold justify-center items-center text-center">
            <div className="md:mt-16 inline-flex gap text-xl md:text-5xl">
              <NavLink to="/services" className="relative">
                <button className="bg-gradient-to-r from-blue-950 to-blue-950 text-white px-3 md:px-5 py-3 md:py-5 rounded-full flex items-center justify-between mx-2 my-4 text-xl hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500">
                  Know More
                  <HiChevronRight className="ml-2" />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <AllServices className="grid grid-cols-2"></AllServices>

      {/* We Work on Technologies */}
      <div className="bg-pink-100 shadow-2xl rounded-3xl py-20">
        <div className="md:text-5xl mt-9 text-2xl font-bold text-center">
          We Work on Technologies
        </div>
        <p className="text-2xl text-center mt-5">
          We are committed to providing our customers with exceptional.
        </p>
        <div className="py-10">
          <Marquee pauseOnHover={true} className="marquee-container">
            <div className="w-32 mx-2">
              <img src={CSS} alt="" className="w-32 mx-2" />
            </div>
            <div className="w-32 mx-2">
              <img src={Figma} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={android} alt="" className="" />
            </div>
            <div className="w-20">
              <img src={IOS} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={html} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={MySql} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={Python} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={Jquery} alt="" className="" />
            </div>
            <div className="w-32 mx-2">
              <img src={MongoDB} alt="" className="" />
            </div>
          </Marquee>
        </div>
        <NavLink
          className="w-fit bg-blue-900 text-white px-6 py-3 mx-auto rounded-full gap-2 flex items-center justify-between text-lg md:text-xl font-bold"
          to="/services"
        >
          <span>Our Services</span>
          <PiArrowSquareUpRightBold />
        </NavLink>
      </div>

      <section className="flex md:shadow-2xl bg-white justify-around">
        <BoxWithImageAndText
          imgSrc={usersicon}
          imgAlt="Logo"
          title="Countries Served"
          value={7}
        />

        <BoxWithImageAndText
          imgSrc={Marketing}
          imgAlt="Logo"
          title="Project Completed"
          value={117}
        />

        <BoxWithImageAndText
          imgSrc={meeting}
          imgAlt="Logo"
          title="Project Experts"
          value={23}
        />

        <BoxWithImageAndText
          imgSrc={mobilebanner}
          imgAlt="Logo"
          title="Years of Experience"
          value={10}
        />
      </section>
      <AllServeIndustries />
    </div>
  );
}

const BoxWithImageAndText = ({ imgSrc, imgAlt, title, value }) => (
  <div className="flex flex-col items-center justify-center bg-white  m-4">
    <img src={imgSrc} alt={imgAlt} className="w-20 h-20 object-contain" />
    <h3 className="text-2xl font-bold mt-4">{title}</h3>
    <p className="text-2xl font-semibold text-blue-600 text-center mt-2">{value}</p>
  </div>
);



export default Home;
