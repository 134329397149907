import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import {  HiUser, HiUserGroup,  HiMiniCheckBadge  } from "react-icons/hi2";
import { GrServices } from "react-icons/gr";

function AdminLayout() {
  return (

    <div className='flex'>
      <div className="hover:text-blue-600 ml-3 p-3 flex items-center text-lg">
        <HiUser className="text-slate-800" />
        <NavLink className="navlink" to="/admin/users">Users</NavLink>
      </div>
     <div className="hover:text-blue-600 ml-3 p-3 flex items-center text-lg"> 
     <HiMiniCheckBadge className="text-slate-800" />
        <NavLink className="navlink" to="/admin/eccouncildata">EC-Council</NavLink>
     </div>
    <div className="hover:text-blue-600 ml-3 p-3 flex items-center text-lg"> 
    <HiUserGroup className="text-slate-800" />   
      <NavLink className="navlink" to="/admin/apply">UserApply</NavLink>
    </div>
    <div className="hover:text-blue-600 ml-3 p-3 flex items-center text-lg">
      <GrServices className="text-slate-800" />
      <NavLink className="navlink" to="/admin/enquiry">UserEnquiry</NavLink>
    </div>
    <Outlet />
    </div>
    
  )
}

export default AdminLayout