import React, { useState } from 'react';
import { Card } from "flowbite-react";

import Softwaredev from '../../assets/Software-dev.jpg';
import WebsiteDev from '../../assets/Mca.jpg';
import Mobiledev from '../../assets/Website-Dev.jpg';
import ITConsultancy from '../../assets/bca.jpg'
import servicesimg from '../../assets/services.jpg';

function Services() {
     const [showMore, setShowMore] = useState({});
  
  const toggleShowMore = (key) => {
    setShowMore(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };
  const services = [
    {
      id: 1,
      title: "Software Development",
      description: "DigiSoul Tech Pvt Ltd. delivers exceptional software solutions, providing value and regular updates to clients. With a focus on security and future-proofing, our expert team ensures client satisfaction by exceeding expectations. We work collaboratively, using agile methods to deliver timely, budget-friendly results.",
      logoImage: Softwaredev
    },
    {
      id: 2,
      title: "IT Consultancy",
      description: "DigiSoul Tech Pvt Ltd. offers expert advice from experienced professionals who research markets, analyze current trends, and envision future scenarios. With a thorough understanding of clients' needs, they devise comprehensive IT strategies that address present challenges and anticipate future ones.",
      logoImage: WebsiteDev
    },
     {
      id: 3,
      title: "Website Development",
      description: "Our creative web development team at DigiSoul Tech Pvt Ltd engages closely with clients to understand their needs and delivers purpose-driven websites. Focusing on design, SEO, and accessibility, we ensure a great user experience across all devices. Security and audience reach are top priorities, with features like screen readers for the disabled.",
      logoImage: Mobiledev
    },
     {
      id: 4,
      title: "Mobile Application Development",
      description: "Our innovative team at DigiSoul Tech Pvt Ltd creates mobile apps that stand out. We blend tech expertise with market research to develop user-friendly apps. With thorough testing, we ensure smooth, efficient, and future-proof applications delivered on time and within budget.",
      logoImage: ITConsultancy
    },
     {
      id: 5,
      title: "CyberSecurity",
      description: "DigiSoul Tech Pvt Ltd. provides top-tier security solutions and consulting services, safeguarding businesses' confidential data. Our experts stay updated with the latest data security trends, focusing not only on identifying and mitigating risks but also on proactive solutions for future challenges.",
      logoImage: servicesimg
    }
  ];

  return (
    <div>
      <div className=" ml-28">
        <div className="text-4xl font-serif  mt-6 text-fuchsia-950 underline">
          Services
        </div>
        <div className="text-2xl font-bold text-red-900 mt-5">
          Stay ahead of the curve with solution designed by our experts.
        </div>
        <p className="mb-4  text-lg mt-5 text-amber-950">
          DigiSoul Tech Pvt Ltd. is providing a wide range of services that is
          helping our business partners and clients to manage their
          technological requirements and stay competitive in today’s digital
          landscape. Our services help in managing the IT infrastructure,
          systems and software applications.
        </p>
      </div>
      <div className="grid grid-cols-2 mb-7 lg:grid-cols-3 ml-40 gap-9">
        {services.map((service) => (
          <div key={service.id} className=" rounded">
            <Card href="#" className=" md:w-72 w-56 ">
              <div className="md:w-30">
                <img
                  src={service.logoImage}
                  alt="logo"
                  className="inline w-full h-60 md:w-30 transition-transform transform-gpu hover:blur-0 mr-0"
                />
              </div>
              <h5 className="text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                {service.title}
              </h5>
              {showMore[service.id] ? (
                <>
                  <p className="font-normal text-gray-700 dark:text-gray-400">
                    {service.description}
                  </p>
                  <button
                    onClick={() => toggleShowMore(service.id)}
                    className="text-blue-500"
                  >
                    Show less
                  </button>
                </>
              ) : (
                <button
                  onClick={() => toggleShowMore(service.id)}
                  className="text-blue-500"
                >
                  Show more
                </button>
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services
