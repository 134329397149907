import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from 'react-icons/bs';
import { HiMiniPhone , HiOutlineHome, HiPencilSquare, HiOutlineClock   } from "react-icons/hi2";
import { NavLink } from 'react-router-dom';
import digilogo from "../../assets/digilogo.png";


function FooterComponet() {
  const openSocialMedia = (url) => {
    window.open(url, "_blank");
  };
  
  return (
    <Footer>
      <div className="w-full bg-black">
        <div className="ml-20 mt-3 flex items-center">
          <NavLink to="/" className="flex items-center">
            <img
              src={digilogo}
              alt="logo"
              className="inline w-44 md:w-34 transition-transform hover:blur-0"
            />
            <div className="text-3xl font-bold text-fuchsia-900">
              DigiSoulTech
            </div>
          </NavLink>
        </div>
        <div className="grid py-4 md:grid-cols-5 grid-cols-2 gap-5 ml-24 mr-16 ">
          <div>
            <Footer.Title
              className="text-white font-bold text-xl"
              title="Important Links"
            />
            <Footer.LinkGroup col className="text-lg">
              <NavLink to="/" className="hover:text-pink-600 text-gray-50">
                Home
              </NavLink>
              <NavLink
                to="/eccouncil"
                className="hover:text-pink-600 text-gray-50"
              >
                EC-Council
              </NavLink>
              <NavLink
                to="/aboutus"
                className="hover:text-pink-600 text-gray-50"
              >
                About Us
              </NavLink>
              <NavLink
                to="/services"
                className="hover:text-pink-600 text-gray-50"
              >
                Services
              </NavLink>
              <NavLink
                to="/contactus"
                className="hover:text-pink-600  text-gray-50"
              >
                Contact Us
              </NavLink>
              <NavLink
                to="/career"
                className="hover:text-pink-600 text-gray-50"
              >
                Career
              </NavLink>
            </Footer.LinkGroup>
          </div>

          <div>
            <Footer.Title
              className="text-white font-bold text-xl"
              title="Development Solution"
            />
            <Footer.LinkGroup col className=" text-lg">
              <NavLink
                to="/websitedesigning"
                className="hover:text-pink-600 text-gray-50"
              >
                Website Design
              </NavLink>
              <NavLink
                to="/websitedevelopment"
                className="hover:text-pink-600 text-gray-50"
              >
                Website Development
              </NavLink>
              <NavLink
                to="/appdevelopment"
                className="hover:text-pink-600 text-gray-50"
              >
                App Development
              </NavLink>
              <NavLink
                to="/responsivewebsite"
                className="hover:text-pink-600 text-gray-50"
              >
                Responsive{" "}
              </NavLink>
              <NavLink
                to="/websiteredesign"
                className="hover:text-pink-600 text-gray-50"
              >
                Website Re-Design{" "}
              </NavLink>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title
              className="text-white font-bold text-xl"
              title="Trending Solution"
            />
            <Footer.LinkGroup col className=" text-lg">
              <NavLink
                to="/CSC-Academy"
                className="hover:text-pink-600 text-gray-50"
              >
                Digital-India CSC
              </NavLink>
              <NavLink
                to="/ethicalhacking"
                className="hover:text-pink-600 text-gray-50"
              >
                Ethical Hacking
              </NavLink>
              <NavLink
                to="/council"
                className="hover:text-pink-600 text-gray-50"
              >
                EC-Council
              </NavLink>
              <NavLink
                to="/hosting"
                className="hover:text-pink-600 text-gray-50"
              >
                Hosting Services
              </NavLink>
              <NavLink to="/seo" className="hover:text-pink-600 text-gray-50">
                SEO Solution
              </NavLink>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title
              className="text-white font-bold text-xl"
              title="Digital Marketing"
            />
            <Footer.LinkGroup col className="text-lg">
              <NavLink
                to="/digitalmarketing"
                className=" text-gray-50 hover:text-pink-600 "
              >
                Digital Marketing
              </NavLink>
              <NavLink
                to="/ecommerce"
                className=" text-gray-50 hover:text-pink-600 "
              >
                E-Commerce
              </NavLink>
              <NavLink
                to="/cloudcomputing"
                className=" text-gray-50 hover:text-pink-600 "
              >
                Data Entry
              </NavLink>
              <NavLink
                to="/uiuxdev"
                className=" text-gray-50 hover:text-pink-600 "
              >
                Email Services
              </NavLink>
              <NavLink
                to="/softwaredev"
                className=" text-gray-50 hover:text-pink-600 "
              >
                SMS services
              </NavLink>
            </Footer.LinkGroup>
          </div>
          <div className="">
            <Footer.Title
              className="text-white font-bold text-xl"
              title="Connect with DigiSoul"
            />
            <Footer.LinkGroup col className="text-lg">
              <div className="flex items-center">
                <div className="hover:text-slate-100 text-gray-50 inline-flex ">
                  <HiMiniPhone className="mr-2" />
                  +91 9835940608<br/> +91 6513115436<br/> +91 6513103422{" "}
                </div>
              </div>
              <div className="hover:text-slate-100 text-gray-50 inline-flex">
                <HiOutlineHome className="mr-2" />
                Head Office - 3rd Floor, Amravati Complex, Lalpur chowk, Ranchi<br />
                Regional Office - 2nd Floor, Amravati Complex, Lalpur chowk,
                Ranchi
              </div>
              <div className="hover:text-slate-100 text-gray-50 inline-flex items-center">
                <HiPencilSquare className="mr-2" />
                info@digisoultech.com
              </div>
              <div className="hover:text-slate-100 text-gray-50 inline-flex items-center">
                <HiOutlineClock className="mr-2" />
                Mon - Sat : 9.00 am - 6.00 pm
              </div>
            </Footer.LinkGroup>
          </div>
        </div>
        <hr style={{ marginLeft: "100px", marginRight: "60px" }} />
        <div className=" text-white px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="/" by="DigiSoulTech Pvt Ltd." year={2022} />
          <div className="mt-4 flex space-x-6 sm:mt-0 mr-14 sm:justify-center">
            <button
              onClick={() =>
                openSocialMedia("https://www.facebook.com/digisoultechranchi")
              }
            >
              <BsFacebook className=" text-2xl hover:text-pink-600" />
            </button>
            <button
              onClick={() =>
                openSocialMedia("https://www.instagram.com/digi_soultech/")
              }
            >
              <BsInstagram className=" text-2xl hover:text-pink-600" />
            </button>
            <button
              onClick={() =>
                openSocialMedia(
                  "https://www.linkedin.com/company/90858382/admin/feed/posts/"
                )
              }
            >
              <BsLinkedin className=" text-2xl hover:text-pink-600" />
            </button>
            <button
              onClick={() =>
                openSocialMedia(
                  "https://www.youtube.com/channel/UCoGc4VoB5Op15i5YlSWAGUA"
                )
              }
            >
              <BsYoutube className=" text-2xl hover:text-pink-600" />
            </button>
            <button
              onClick={() => openSocialMedia("https://www.digisoultech.com/")}
            >
              <BsDribbble className=" text-2xl hover:text-pink-600" />
            </button>
          </div>
        </div>
      </div>
    </Footer>
  );
}
export default FooterComponet;