import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import amazonlogo from "../../assets/amazon-logo.png";
import amazonbanner from "../../assets/amazonbanner.webp";
import partnership from "../../assets/patnership.png";
import digisoultechlogo from "../../assets/digilogo.png";
import { IoMdClose } from "react-icons/io";

const Amazon = () => {
  const [showForm, setShowForm] = useState(false);
  const [user, setUser] = useState({
    owner_name: "",
    company_name: "",
    email: "",
    contact_no: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 5000); 

    return () => clearTimeout(timer); 
  }, []);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    const { owner_name, company_name, email, contact_no } = user;
    if (owner_name && company_name && email && contact_no) {
      setShowForm(false);
    } else {
      alert("Please fill all the fields before closing.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { owner_name, company_name, email, contact_no } = user;

    if (!owner_name || !company_name || !contact_no) {
      alert("All fields are required.");
      return;
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        owner_name,
        company_name,
        email,
        contact_no,
      }),
    };

    try {
      const res = await fetch(
        "https://digisoultech-c9690-default-rtdb.firebaseio.com/registrations.json",
        options
      );

      if (res.ok) {
        alert(" Seller Registered Data Saved");
        setShowForm(false);
        setUser({
          owner_name: "",
          company_name: "",
          email: "",
          contact_no: "",
        });
      } else {
        alert("Error Occurred: " + res.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert("Fetch error: " + error.message);
    }
  };

  return (
    <div>
      <nav className="bg-white flex justify-between items-center shadow-md">
        <div>
          <NavLink to="/" className="flex items-center ml-14">
            <img
              src={amazonlogo}
              alt="logo"
              className="inline w-32 transition-transform transform-gpu hover:blur-0"
            />
            <div className="flex flex-col">
              <span className="text-lg -ml-7 mb-2 font-bold text-blue-950">
                .in
              </span>
            </div>
          </NavLink>
        </div>
        <button
          onClick={handleButtonClick}
          className="bg-yellow-400 text-slate-950 py-2 px-4 rounded-full mr-28"
        >
          Start Selling
        </button>
      </nav>
      <div className="flex items-center justify-between p-4 ml-14">
        <div className="space-y-4">
          <h1 className="font-bold text-4xl">Become an Amazon seller</h1>
          <p className="text-lg">
            Join millions of successful sellers on Amazon.in and take your
            business to new heights. Start your selling journey today and become
            a part of a thriving community dedicated to growth and innovation.
          </p>
          <button
            onClick={handleButtonClick}
            className="bg-yellow-400 text-slate-950 py-2 px-4 rounded-full"
          >
            Start Selling
          </button>
        </div>
        <img src={amazonbanner} alt="amazon" className="w-1/3" />
      </div>

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <div className="flex justify-end">
              <button
                onClick={handleCloseForm}
                className="text-gray-500 hover:text-gray-700"
              >
                <IoMdClose className="h-6 w-6" />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-4">
              Amazon Seller Registration
            </h2>
            <form method="post" className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-700">
                  Business Owner Name
                </label>
                <input
                  id="owner_name"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="owner_name"
                  value={user.owner_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Company Name</label>
                <input
                  id="company_name"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="company_name"
                  value={user.company_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  id="email"
                  type="email"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Contact Number</label>
                <input
                  id="contact_no"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="contact_no"
                  value={user.contact_no}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full p-2 bg-yellow-400 text-white rounded hover:bg-yellow-200"
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-5xl font-bold mb-6 text-blue-900">
          PARTNERSHIP WITH AMAZON
        </h1>
        <div className="flex justify-center space-x-8 mb-6">
          <img
            src={amazonlogo}
            alt="Amazon logo"
            className="inline w-48 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
          />
          <img
            src={partnership}
            alt="Partnership logo"
            className="inline w-48 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
          />
          <img
            src={digisoultechlogo}
            alt="DigiSoulTech logo"
            className="inline w-48 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
          />
        </div>
        <h1 className="text-5xl font-bold mt-6 text-blue-900">
          SUCCEED WITH US
        </h1>
      </div>
    </div>
  );
};

export default Amazon;
