import React from "react";
import { VscCircleFilled } from "react-icons/vsc";
import ECCouncilcertification from "./ECCouncilcertification";
import learnicon from "../../assets/learnicon1.png";
import certifyicon from "../../assets/certify.png";
import completeicon from "../../assets/complete.png";
import engageicon from "../../assets/engage.png";

const IconText = ({ text, hasIcon = true }) => (
  <div className="flex items-center mb-2">
    {hasIcon && <VscCircleFilled />}
    <h3 className={hasIcon ? "ml-2" : ""}>{text}</h3>
  </div>
);

const Section = ({ imgSrc, imgAlt, imgClass, title, items }) => (
  <div className="ml-10 mb-10">
    <img src={imgSrc} alt={imgAlt} className={imgClass} />
    {title && <h3 className="font-bold text-xl p-4">{title}</h3>}
    <div>
      {items.map((item, index) => (
        <IconText key={index} text={item} hasIcon={!item.startsWith("-")} />
      ))}
    </div>
  </div>
);

function ECCouncinDes() {
  return (
    <div>
      <div className="font-bold text-4xl text-center">
        <h2>Enter the Hackerverse With the C|EH® v12</h2>
        <h5 className="text-xl mt-2">Enhance Your Ethical Hacking Career</h5>
        <hr className="border-black w-8/12 mx-auto p-5 mt-5" />
      </div>
      <div className="flex">
        <div className="w-1/2">
          <Section
            imgSrc={learnicon}
            imgAlt="learn1"
            imgClass="w-72 h-44 p-10"
            items={[
              "5 days of training",
              "20 modules",
              "3000+ pages of student manual",
              "1900+ pages of lab manual",
              "Over 200 hands-on labs with competition flags",
              "Over 3,500 hacking tools - Learn how to hack multiple operating systems (Windows 11, Windows servers, Linux, Ubuntu, Android)",
              "MITRE Attack Framework",
              "Diamond model of intrusion analysis",
              "Techniques for establishing persistence",
              "Evading NAC and endpoint security",
              "Understand Fog, Edge, and Grid Computing Model",
            ]}
          />

          <Section
            imgSrc={engageicon}
            imgAlt="engage3"
            imgClass="w-72 h-44 p-10"
            items={[
              "Conduct a real-world ethical hacking assignment",
              "Apply the 5 phases",
              "- Reconnaissance",
              "- Scanning",
              "- Gaining Access",
              "- Maintaining Access",
              "- Covering Your Tracks",
            ]}
          />
        </div>

        <div className="border-l-2 border-black mx-5"></div>

        <div className="w-1/2">
          <Section
            imgSrc={certifyicon}
            imgAlt="certify2"
            imgClass="w-72 h-44 p-10"
            title="C|EH® ANSI & Practical"
            items={[
              "125 Multiple-Choice Questions",
              "4 hours",
              "6-hour Practical Exam",
              "20 Scenario-Based Questions",
            ]}
          />

          <Section
            imgSrc={completeicon}
            imgAlt="complete4"
            imgClass="w-72 h-44 p-10"
            items={[
              "New challenges every month",
              "4-hour competition",
              "Compete with your peers all over the world",
              "Hack your way to the top of the leaderboard",
              "Gain recognition",
              "Challenges include:",
              "- OWASP Top 10 Web Application Threat Vectors",
              "- Ransomware/ Malware Analysis",
              "- Outdated/Unpatched Software",
              "- System Hacking and Privilege Escalation",
              "- Web Application Hacking and Pen Testing",
              "- Cloud Attack/Hacking",
              "- and many more...",
            ]}
          />
        </div>
      </div>
      <div>
        <img src={learnicon} alt="banner" className="w-72 h-44 p-10" />
        <p className="text-lg ml-16 mr-10">
          The C|EH® v12 training program includes 20 modules covering various
          technologies, tactics, and procedures, providing prospective ethical
          hackers with the core knowledge needed to thrive in cybersecurity.
          Delivered through a carefully curated training plan that typically
          spans five days, the 12th version of the C|EH® continues to evolve to
          keep up with the latest OS, exploits, tools, and techniques. The
          concepts covered in the training program are split 50/50 between
          knowledge-based training and hands-on application through our cyber
          range.Every tactic discussed in training is backed by step-by-step
          labs conducted in a virtualized environment with live targets, live
          tools, and vulnerable systems. Through our lab technology, every
          participant will have comprehensive hands-on practice to learn and
          apply their knowledge.”
        </p>
        <div className="text-2xl font-bold p-16">
          <h2>Course Outline</h2>
          <h2>
            20 Modules That Help You Master the Foundations of Ethical Hacking
            and Prepare to Take the C|EH Certification Exam
          </h2>
        </div>
        <div>
          <ModuleSection
            moduleNumber="01"
            title="Introduction to Ethical Hacking"
            description="Cover the fundamentals of key issues in the information security world, including the basics of ethical hacking, information security controls, relevant laws, and standard procedures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="02"
            title="Foot Printing and Reconnaissance"
            description="Learn how to use the latest techniques and tools to perform foot 
                   printing and reconnaissance, a critical pre-attack phase of the ethical 
                   hacking process."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="03"
            title="Scanning Networks"
            description="Learn different network scanning techniques and countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="04"
            title="Enumeration"
            description="Learn various enumeration techniques, such as Border Gateway 
                  Protocol (BGP) and Network File Sharing (NFS) exploits, and associated 
                  countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="05"
            title="Vulnerability Analysis"
            description="Learn how to identify security loopholes in a target organization’s 
                       network, communication infrastructure, and end systems. Different 
                       types of vulnerability assessment and vulnerability assessment tools."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="06"
            title="System Hacking"
            description="Learn about the various system hacking methodologies—including steganography, steganalysis attacks, and covering tracks—used to discover 
                     system and network vulnerabilities."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="07"
            title="Malware Threats"
            description="Learn different types of malware (Trojan, virus, worms, etc.), APT 
                 and fileless malware, malware analysis procedure, and malware 
                countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="08"
            title="Sniffing"
            description="Learn about packet-sniffing techniques and how to use them to discover 
                 network vulnerabilities, as well as countermeasures to defend against 
                 sniffing attacks."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="09"
            title="Social Engineering"
            description="Learn social engineering concepts and techniques, including how to 
                    identify theft attempts, audit human-level vulnerabilities, and suggest 
                    social engineering countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="10"
            title="Denial-of-Service"
            description="Learn about different Denial of Service (DoS) and Distributed DoS 
                 (DDoS) attack techniques, as well as the tools used to audit a target and 
                 devise DoS and DDoS countermeasures and protections."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="11"
            title="Session Hijacking"
            description="Understand the various session hijacking techniques used to discover 
                    network-level session management, authentication, authorization, and 
                     cryptographic weaknesses and associated countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="12"
            title="Evading IDS, Firewalls, and Honeypots"
            description="Get introduced to firewall, intrusion detection system (IDS), and 
                  honeypot evasion techniques; the tools used to audit a network 
                  perimeter for weaknesses; and countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="13"
            title="Hacking Web Servers"
            description="Learn about web server attacks, including a comprehensive attack 
                 methodology used to audit vulnerabilities in web server infrastructures 
                 and countermeasures."
          />
          <ModuleSection
            moduleNumber="14"
            title="Hacking Web Applications"
            description="Learn about web application attacks, including a comprehensive web 
                 application hacking methodology used to audit vulnerabilities in web 
                 applications and countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="15"
            title="SQL Injection"
            description="Learn about SQL injection attacks, evasion techniques, and SQL 
                   injection countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="16"
            title="Hacking Wireless Networks"
            description="Understand different types of wireless technologies, including 
                  encryption, threats, hacking methodologies, hacking tools, Wi-Fi 
                  sedcurity tools, and countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="17"
            title="Hacking Mobile Platforms"
            description="Learn Mobile platform attack vector, android and iOS hacking, mobile 
                    device management, mobile security guidelines, and security tools."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="18"
            title="IoT and OT Hacking"
            description="Learn different types of IoT and OT attacks, hacking methodology, 
                  hacking tools, and countermeasures."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="19"
            title="Cloud Computing"
            description="Learn different cloud computing concepts, such as container technologies
                 and server less computing, various cloud computing threats, attacks, 
                 hacking methodology, and cloud security techniques and tools."
          />
          <hr className="border-black w-10/12 mx-auto" />
          <ModuleSection
            moduleNumber="20"
            title="Cryptography"
            description="Learn about encryption algorithms, cryptography tools, Public Key 
                  Infrastructure (PKI), email encryption, disk encryption, cryptography 
                  attacks, and cryptanalysis tools."
          />
          <hr className="border-black w-10/12 mx-auto" />
        </div>
      </div>
      <div className="text-center p-10">
        <h1 className="text-3xl font-bold">HANDS-ON LEARNING LABS</h1>
        <p className="text-lg ">
          With over 220 hands-on labs conducted in our cyber range environment,
          you will have the opportunity to practice every learning objective on
          live machines and vulnerable targets in the course. Pre-loaded with
          over 3,500 hacking tools and various operating systems, you will gain
          unprecedented exposure and hands-on experience with the most common
          security tools, latest vulnerabilities, and widely used operating
          systems in the industry. Our range is web accessible, making it easier
          for you to learn and practice from anywhere.
        </p>
      </div>
      <h2 className="font-bold text-3xl ml-10">What’s Covered:</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xl font-bold p-10">
        <div>
          <h4 className="border-2 border-red-500 p-4 my-4">
            100% virtualization for a complete learning experience
          </h4>
          <h4 className="border-2 border-red-500 p-4 my-4">
            After login, you will have full access to pre-configured targets,
            networks, and the attack tools necessary to exploit them:
            <Section
              items={[
                "Pre-configured vulnerable websites",
                "Vulnerable, unpatched operating systems",
                "Fully networked environments",
                "3,500+ hacking tools",
                "And much more!",
              ]}
            />
          </h4>
        </div>
        <div>
          <h4 className="border-2 border-red-500 p-4 my-4">
            Wide range of target platforms to hone your skills
          </h4>
          <h4 className="border-2 border-red-500 p-4 my-4">
            519 attack techniques
          </h4>
          <h4 className="border-2 border-red-500 p-4 my-4">
            Objective-oriented flags for critical thinking and applied knowledge
            assessment
          </h4>
          <h4 className="border-2 border-red-500 p-4 my-4">
            Cloud-based cyber range
          </h4>
        </div>
      </div>
      <ECCouncilcertification />
    </div>
  );
}

export default ECCouncinDes;

const ModuleSection = ({ moduleNumber, title, description }) => {
  return (
    <div className="flex items-start space-x-4 p-10 ml-16">
      <div className="border-r-2 border-black h-full pr-9">
        <h3 className="m-0 font-bold text-2xl">Module</h3>
        <h3 className="m-0 font-bold text-2xl bg-red-600 p-2 text-white">
          {moduleNumber}
        </h3>
      </div>
      <div className="ml-6">
        <h3 className="font-bold text-2xl">{title}</h3>
        <p className="text-xl">{description}</p>
      </div>
    </div>
  );
};
