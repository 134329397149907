import React from 'react'
import engageicon from "../../assets/engage.png";
import { VscCircleFilled } from "react-icons/vsc";
import ECCouncilcomplete from "../pages/ECCouncilcomplete";

const IconText = ({ text, hasIcon = true }) => (
  <div className="flex items-center mb-2">
    {hasIcon && <VscCircleFilled />}
    <h3 className={hasIcon ? "ml-2" : ""}>{text}</h3>
  </div>
);

const Section = ({ title, items }) => (
  <div className=" ml-40 mb-10 border border-red-500">
    {title && <h3 className="font-bold text-xl p-4">{title}</h3>}
    <div>
      {items.map((item, index) => (
        <IconText key={index} text={item} hasIcon={!item.startsWith("-")} />
      ))}
    </div>
  </div>
);
function ECCouncilengage() {
  return (
    <div>
      <img src={engageicon} alt="banner" className="w-72 h-44 p-9" />
      <p className=" ml-14 mr-6 text-lg">
        The C|EH® v12 program helps you develop real-world experience in ethical
        hacking through the hands-on C|EH® practice environment. The C|EH®
        Engage equips you with the skills to prove that you have what it takes
        to be a great ethical hacker. New to C|EH® v12, students will embark on
        their first emulated ethical hacking engagement. This 4-phase engagement
        requires students to think critically and test the knowledge and skills
        gained by capturing a series of flags in each phase, demonstrating the
        live application of skills and abilities in a consequence-free
        environment through EC-Council’s new Cyber Range. As you complete your
        training and hands-on labs, the C|EH® Engage lets you apply everything
        you have learned in a mock ethical hacking engagement. This 4-part
        security engagement gives you a real ethical hacking engagement
        experience from start to finish against an emulated organization. Using
        our capture-the-flag-style range, you will complete your engagement by
        answering “flag” questions as you progress.
      </p>
      <h1 className="text-3xl font-bold ml-14 mt-6">Your Mission</h1>
      <p className=" ml-14 mr-6 text-lg">
        Whether this is your first engagement or you’re honing your skills, get
        ready to test your ethical hacking knowledge like never before! Once
        you’ve practiced through the hands-on guided labs, it’s time to apply
        your knowledge, take on the hacker persona, and find the vulnerabilities
        and weaknesses in ABCDorg—all built in our C|EH® Engage (practice
        range).
      </p>
      <div className="container mx-auto p-10 bg-red-500 mt-10">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Target Organization Characteristics
        </h1>
        <div className="grid grid-cols-4 gap-4">
          <div className="border p-4">
            ABCD is a Nationwide IT/ITES organization
          </div>
          <div className="border p-4">Realistic segmented networks</div>
          <div className="border p-4">
            DMZs’s and private subnets stretch across the infrastructure to
            support various business units
          </div>
          <div className="border p-4">
            Various application servers and services support ABCDORG Operations
          </div>
          <div className="border p-4">
            Real networks, real operating systems, and real applications
          </div>
          <div className="border p-4">
            Private, dedicated access – no shared resources
          </div>
          <div className="border p-4">
            Fully automated network deployment with EC-Council’s Cyber Range
          </div>
          <div className="border p-4">24x7 browser-based access</div>
        </div>
      </div>
      <h2 className="text-3xl font-bold ml-14 mt-10">Objectives:</h2>
      <p className=" ml-20 mr-10 mt-2 text-lg">
        Armed with your attack platform, Parrot OS, and a plethora of tools used
        by Ethical Hackers, you will embark on a 4-part engagement to assess
        ABCDorg’s security posture. Follow the process, practice your TTP and
        experience the real thing in a controlled environment with no
        consequences, just the ultimate learning experience to support your
        career as an Ethical Hacker! Each phase builds on the last as you
        progress through your ABCDorg’s engagement.
      </p>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Section
            title="PHASE 1 Vulnerability Assessment"
            items={[
              "Foot Printing & Reconnaissance",
              "Scanning",
              "Enumeration",
              "Vulnerability Analysis",
            ]}
          />
          <Section
            title="PHASE 2 Gaining Access"
            items={[
              "System Hacking",
              "Malware Threats",
              "Sniffing",
              "Social Engineering",
              "Denial-of-Service",
            ]}
          />
          <Section
            title="PHASE 3 Perimeter and Web App Exploitation"
            items={[
              "Session Hijacking",
              "Evading IDS",
              "Firewalls",
              "Honeypots",
              "Hacking Web Servers",
              "Hacking Web Applications",
              "SQL Injection",
            ]}
          />
          <Section
            title="PHASE 4 Mobile, IoT, OT Exploitation"
            items={[
              "Hacking Wireless Networks",
              "Hacking Mobile Platforms",
              "IoT Hacking",
              "OT Hacking",
              "Cloud Computing",
              "Cryptography",
            ]}
          />
        </div>
      </div>
      <h1 className="text-3xl font-bold ml-20">
        Put Your Skills and Knowledge to the Test With the C|EH® Master
      </h1>
      <p className=" ml-20 mr-10 mt-2 text-lg">
        Once you have achieved the certification and completed your ethical
        hacking engagement, you are ready to challenge the proctored C|EH®
        practical assessment and become a C|EH® Master!
      </p>
      <ECCouncilcomplete />
    </div>
  );
}

export default ECCouncilengage
