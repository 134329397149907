import React from "react";
import { NavLink } from "react-router-dom";

import webdesign from "../../assets/web-design.png";
import webdevelopment from "../../assets/web-development.png";
import ecommerce from "../../assets/e-commerce.png";
import hosting from "../../assets/hosting.png";
import digitalMerketing from "../../assets/digital-Merketing.png";
import sms from "../../assets/sms.png";
import rewebdesign from "../../assets/re-web-design.png";
import responsivewebsite from "../../assets/responsive-website.png";
import Appdevelopment from "../../assets/App-development.png";
import email2 from "../../assets/email2.png";
import seo from "../../assets/seo.png";
import DataEntry from "../../assets/Data-Entry.png";
import meeting from "../../assets/meeting.png";
import Ethicalhacking from "../../assets/Ethical-hacking.png";
import DigitalIndiaCSC from "../../assets/Digital-IndiaCSC.jpeg";

function AllServices() {
  return (
    <div className="py-16 ">
      <div className="text-center text-4xl font-bold text-blue-950 mb-5">
        We customize The Perfect Solution
      </div>
      <p className="text-center text-lg text-slate-700 mb-10">
        We are committed to providing our customers with exceptional service
        while offering our
        <br /> employees the best training.
      </p>
      <div className="flex flex-wrap gap-4 md:ml-40 mt-5 -mb-3 ml-10">
        <CardNavLink
          to="/websitedesigning"
          imgSrc={webdesign}
          imgAlt="webdesign Logo"
          text="Website Designing"
        />
        <CardNavLink
          to="/websitedevelopment"
          imgSrc={webdevelopment}
          imgAlt="webdevelopment Logo"
          text="Website Development"
        />
        <CardNavLink
          to="/hosting"
          imgSrc={ecommerce}
          imgAlt="E-Commerce Logo"
          text="E-Commerce Solution"
        />
        <CardNavLink
          to="/ecommerce"
          imgSrc={hosting}
          imgAlt="Ecommerce Logo"
          text="Hosting Services"
        />
        <CardNavLink
          to="/digitalmarketing"
          imgSrc={digitalMerketing}
          imgAlt="Digital Marketing Logo"
          text="digital Merketing"
        />
        <CardNavLink
          to="/sms"
          imgSrc={sms}
          imgAlt="sms Logo"
          text="SMS Services"
        />
        <CardNavLink
          to="/websiteredesign"
          imgSrc={rewebdesign}
          imgAlt="rewebdesign Logo"
          text="Website Re-Design"
        />
        <CardNavLink
          to="/responsivewebsite"
          imgSrc={responsivewebsite}
          imgAlt="responsive Logo"
          text="Responsive Website"
        />
        <CardNavLink
          to="/appdevelopment"
          imgSrc={Appdevelopment}
          imgAlt="appdev Logo"
          text="App Development"
        />
        <CardNavLink
          to="/emailservices"
          imgSrc={email2}
          imgAlt="email Logo"
          text="Email Services"
        />
        <CardNavLink
          to="/seo"
          imgSrc={seo}
          imgAlt="seo Logo"
          text="SEO Solution"
        />
        <CardNavLink
          to="/blockchain"
          imgSrc={DataEntry}
          imgAlt="Ecommerce Logo"
          text="Blockchain Technology"
        />
        <CardNavLink
          to="/council"
          imgSrc={meeting}
          imgAlt="eccouncil Logo"
          text="EC-Council"
        />
        <CardNavLink
          to="/ethicalhacking"
          imgSrc={Ethicalhacking}
          imgAlt="hacking Logo"
          text="Ethical Hacking"
        />
        <CardNavLink
          to="/CSC-Academy"
          imgSrc={DigitalIndiaCSC}
          imgAlt="Ecommerce Logo"
          text="Digital-india CSC Academy"
        />
      </div>
    </div>
  );
}

export default AllServices;

const CardNavLink = ({ to, imgSrc, imgAlt, text }) => {
  return (
    <NavLink
      className="md:w-60 w-52 mb-5 hover:bg-slate-100 bg-white brightness-100 shadow-xl rounded-2xl text-center animate-jump"
      to={to}
    >
      <img
        src={imgSrc}
        alt={imgAlt}
        className="inline w-20 md:w-30 md:mr-2 transition-transform mt-8 transform-gpu hover:-translate-y-3 hover:blur-0 mr-0"
      />
      <h3 className="text-lg mb-5 text-slate-900">{text}</h3>
    </NavLink>
  );
};
