import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import nttdatalogo from "../../assets/paymentservicelogo.png";
import paymentbanner from "../../assets/paymentbanner.jpg";
import partnership from "../../assets/patnership.png";
import digisoultechlogo from "../../assets/digilogo.png";
import paymentoption from "../../assets/paymentoption.png";
import { IoMdClose } from "react-icons/io";
import { HiOutlineArrowRight } from "react-icons/hi";

const PaymentServices = () => {
  const [showForm, setShowForm] = useState(false);
  const [user, setUser] = useState({
    username: "",
    company_name: "",
    email: "",
    contact_no: "",
  });
  const [formLocked, setFormLocked] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 5000); // 5 seconds delay
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, company_name, contact_no, email } = user;

    if (!username || !company_name || !email || !contact_no) {
      alert("All fields are required.");
      return;
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        company_name,
        email,
        contact_no,
      }),
    };

    try {
      const res = await fetch(
        "https://digisoultech-c9690-default-rtdb.firebaseio.com/registrations.json",
        options
      );

      if (res.ok) {
        alert("Data Saved");
        setShowForm(false);
        setFormLocked(false); // Allow form to be closed after successful submission
        setUser({
          username: "",
          company_name: "",
          email: "",
          contact_no: "",
        });
      } else {
        alert("Error Occurred: " + res.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert("Fetch error: " + error.message);
    }
  };

  return (
    <div>
      <nav className="bg-white flex justify-between items-center shadow-md ">
        <div className="flex items-start ml-20">
          <NavLink to="/" className="flex flex-col items-center">
            <img
              src={nttdatalogo}
              alt="logo"
              className="inline w-32 transition-transform transform-gpu hover:blur-0 mb-2"
            />
          </NavLink>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="bg-blue-500 text-white py-2 px-4 rounded-full mr-28"
        >
          Register
        </button>
      </nav>
      <div className="flex items-center justify-between p-20  ">
        <div className="space-y-6 w-2/3">
          <h1 className="font-bold text-4xl text-blue-950">
            A Payment Gateway Built for Growth
          </h1>
          <p className="text-lg text-gray-700 text-nowrap">
            Experience seamless online transactions with over 100 payment
            options, top-notch
            <br /> success rates, and a secure platform, provided by India's
            leading
            <br /> online payment gateway.
          </p>
          <button
            onClick={() => setShowForm(true)}
            className="bg-blue-500 text-white py-2 px-6 rounded-full"
          >
            Register
          </button>
          <img
            src={paymentoption}
            alt="Payment Options"
            className="w-1/2 h-auto mt-10 "
          />
        </div>
        <img
          src={paymentbanner}
          alt="Payment Banner"
          className="w-1/3 h-80 rounded-lg "
        />
      </div>
      <div className="ml-20 text-gray-700">
        <h1 className=" items-center">Transaction Rules:-</h1>
        <h3 className="flex items-center">
          <HiOutlineArrowRight className="mr-2" />
          Transaction fee charges would not be refunded/ reversed under any
          circumstances for any refund/ reversal /chargeback and any other
          reasons.
        </h3>
        <h3 className="flex items-center">
          <HiOutlineArrowRight className="mr-2" />
          Transaction fees charged would be borne by the Cardholder for any
          payment.
        </h3>
        <h3 className="flex items-center">
          <HiOutlineArrowRight className="mr-2" />
          Fees once paid which are non-refundable for any reason or any clause.
        </h3>
      </div>
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg w-full max-w-md">
            <div className="flex justify-end">
              <button
                onClick={() => (formLocked ? null : setShowForm(false))}
                className={`text-gray-500 hover:text-gray-700 ${
                  formLocked ? "cursor-not-allowed" : ""
                }`}
                disabled={formLocked}
              >
                <IoMdClose className="h-6 w-6" />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-4">
              Payment Service Registration
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700">UserName</label>
                <input
                  id="username"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="username"
                  value={user.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Company Name</label>
                <input
                  id="company_name"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="company_name"
                  value={user.company_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  id="email"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Contact Number</label>
                <input
                  id="contact_no"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  name="contact_no"
                  value={user.contact_no}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-yellow-400 text-slate-950 py-2 px-4 rounded-full"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-4xl font-bold mb-6 text-slate-950 ">
          PARTNERSHIP WITH NTTDATA PAYMENT GATEWAY SERVICE
        </h1>
        <div className="flex justify-center space-x-8 mb-6">
          <NavLink to="/">
            <img
              src={nttdatalogo}
              alt="Amazon logo"
              className="inline w-48 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
            />
          </NavLink>
          <NavLink to="/">
            <img
              src={partnership}
              alt="Partnership logo"
              className="inline w-48 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
            />
          </NavLink>
          <NavLink to="/">
            <img
              src={digisoultechlogo}
              alt="DigiSoulTech logo"
              className="inline w-64 h-48 rounded-lg transition-transform transform-gpu hover:scale-105"
            />
          </NavLink>
        </div>
        <h1 className="text-5xl font-bold mt-6 text-slate-950">
          SUCCEED WITH US
        </h1>
      </div>
    </div>
  );
};

export default PaymentServices;
