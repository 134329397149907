import React from 'react';
import { NavLink } from 'react-router-dom';
import banner1 from '../../assets/BBA.jpg';
import ECCouncilDes from '../pages/ECCouncinDes'

import elite from "../../assets/elite.webp";
import pro from "../../assets/pro.webp";
import normar from "../../assets/Normal.webp";
import { useNavigate } from "react-router-dom";

import { SiMdbook } from "react-icons/si";
import { GoGoal } from "react-icons/go";
import { HiAcademicCap } from "react-icons/hi2";
import { FaPrayingHands } from "react-icons/fa";

import learn from '../../assets/learn1 (2).png';
import certify from '../../assets/certify2.png';
import engage from '../../assets/engage3.png';
import complete from '../../assets/complete4.png';



 const eliteFeatures = [
   { text: "eCourseware ", icon: "check" },
   { text: "Exam Voucher*", icon: "check" },
   { text: "Next Version eCourseware", icon: "check" },
   { text: "6 Months of Official Labs", icon: "check" },
   { text: "C|EH Engage", icon: "check" },
   { text: "Global C|EH Challenges", icon: "check" },
   { text: "Exam Preparation", icon: "check" },
   { text: "C|EH Practical Exam", icon: "check" },
   { text: "Ethical Hacking Video Library", icon: "check" },
   { text: "Exam Retakes**", icon: "check" },
 ];

 const proFeatures = [
   { text: "eCourseware ", icon: "check" },
   { text: "Exam Voucher*", icon: "check" },
   { text: "Next Version eCourseware", icon: "check" },
   { text: "6 Months of Official Labs", icon: "check" },
   { text: "C|EH Engage", icon: "check" },
   { text: "Global C|EH Challenges", icon: "close" },
   { text: "Exam Preparation", icon: "close" },
   { text: "C|EH Practical Exam", icon: "close" },
   { text: "Ethical Hacking Video Library", icon: "5" },
   { text: "Exam Retakes**", icon: "3" },
 ];

 const normarFeatures = [
   { text: "eCourseware ", icon: "check" },
   { text: "Exam Voucher*", icon: "check" },
   { text: "Next Version eCourseware", icon: "check" },
   { text: "6 Months of Official Labs", icon: "close" },
   { text: "C|EH Engage", icon: "close" },
   { text: "Global C|EH Challenges", icon: "close" },
   { text: "Exam Preparation", icon: "close" },
   { text: "C|EH Practical Exam", icon: "close" },
   { text: "Ethical Hacking Video Library", icon: "2" },
   { text: "Exam Retakes**", icon: "1" },
 ];

function ECCouncil() {
  return (
    <>
      <img src={banner1} alt="banner" className="w-full h-svh" />
      <div className="absolute top-48 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <h1 className=" text-red-800 py-8 mt-72 font-serif text-[40px]">
          EC Council
        </h1>
        <div className="text-2xl text-red-800 font-serif ">
          EC Council - Courses Module
        </div>
        <button className="bg-red-800 text-white font-sans mt-6 rounded-md font-bold w-40 h-12 text-lg">
          <NavLink to="/council">Apply Now</NavLink>
        </button>
      </div>
      <div>
        <div className="text-3xl font-bold text-center mt-6 text-slate-900">
          About EC-COUNCIL
        </div>
        <p className="p-10 text-lg text-center text-slate-700">
          EC-Council’s sole purpose is to build and refine the cybersecurity
          profession globally. We help individuals, organizations, educators,
          and governments address global workforce problems by developing and
          curating world-class cybersecurity education programs and their
          corresponding certifications. We also provide cybersecurity services
          to some of the largest businesses globally. Trusted by 7 of the
          Fortune 10, 47 of the Fortune 100, the Department of Defence,
          Intelligence Community, NATO, and over 2,000 of the best Universities,
          Colleges, and Training Companies, our programs have proliferated
          through over 140 countries. They have set the bar in cybersecurity
          education. Best known for the Certified Ethical Hacker programs, we
          are dedicated to equipping over 2,30,000 information age soldiers with
          the knowledge, skills, and abilities required to fight and win against
          the black hat adversaries. EC-Council builds individual and
          team/organization cyber capabilities through the Certified Ethical
          Hacker Program, followed by a variety of other cyber programs,
          including Certified Secure Computer User, Computer Hacking Forensic
          Investigator, Certified Security Analyst, Certified Network Defender,
          Certified SOC Analyst, Certified Threat Intelligence Analyst,
          Certified Incident Handler, as well as the Certified Chief Information
          Security Officer. We are an ANSI 17024 accredited organization and
          have earned recognition by the DoD under Directive 8140/8570 in the UK
          by the GCHQ, CREST, and various other authoritative bodies that
          influence the entire profession. Founded in 2001, EC-Council employs
          over 400 individuals worldwide with ten global offices in the USA, UK,
          Malaysia, Singapore, India, and Indonesia. Its US offices are in
          Albuquerque, NM, and Tampa, FL.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <CourseCard
          imgSrc={elite}
          bgColor="bg-red-600"
          title="Learn, Certify Engage & Complete"
          features={eliteFeatures}
        />
        <CourseCard
          imgSrc={pro}
          bgColor="bg-blue-900"
          title="Learn, Certify & Engage"
          features={proFeatures}
        />
        <CourseCard
          imgSrc={normar}
          bgColor="bg-yellow-200"
          title="Learn & Certify"
          features={normarFeatures}
        />
      </div>
      <div className="bg-red-600 flex text-2xl gap-20 p-5 md:ml-36 md:mr-40 justify-center text-white font-bold">
        <h3>LEARN</h3>
        <h3>CERTIFY</h3>
        <h3>ENGAGE</h3>
        <h3>COMPETE</h3>
      </div>
      <p className="text-xl text-center p-10">
        The C|EH® v12 is a specialized and one-of-a-kind training program to
        teach you everything you need to know about ethical hacking with
        hands-on training, labs, assessment, a mock engagement (practice), and
        global hacking competition. Stay on top of the game with the most
        in-demand skills required to succeed in the field of cybersecurity
      </p>
      <h1 className="text-3xl font-bold text-center">
        Master ethical hacking skills that go beyond the certification.
      </h1>
      <div className="md:grid md:grid-cols-2 gap-4">
        <div>
          <BannerWithText imageSrc={learn} icon={SiMdbook} text="Gain Skills" />
          <BannerWithText
            imageSrc={certify}
            icon={GoGoal}
            text="Gain Recognition"
          />
          <BannerWithText
            imageSrc={engage}
            icon={HiAcademicCap}
            text="Gain Experience"
          />
          <BannerWithText
            imageSrc={complete}
            icon={FaPrayingHands}
            text="Gain Respect"
          />
        </div>
        <div className=" md:mt-96 md:mr-40 mb-5 text-xl   ">
          <p>
            The new learning framework covers not only a comprehensive training
            program to prepare you for the certification exam but also the
            industry’s most robust, in-depth, hands-on lab and practice range
            experience.
          </p>
        </div>
      </div>
      <ECCouncilDes />
    </>
  );
}

export default ECCouncil;

const BannerWithText = ({ imageSrc, icon: Icon, text }) => {
  return (
      <div className="flex items-center">
        <img src={imageSrc} alt="banner" className="md:w-90 w-72 h-72 p-16 md:p-10" />
        <div className="flex flex-col items-center text-lg border-2  border-red-500 p-14  md:p-14">
          <Icon className="md:text-6xl text-3xl" />
          <span className="text-xl">{text}</span>
        </div>
      </div>
  );
};
const CourseCard = ({ imgSrc, bgColor, title, features }) => {
  const navigate = useNavigate();

  const handleInquireClick = () => {
    navigate("/enquiry");
  };

  return (
    <div className={`p-10 mb-10 ml-9 mr-9 rounded-lg shadow-lg ${bgColor}`}>
      <img
        src={imgSrc}
        alt={title}
        className="w-full h-36 object-cover rounded-t-lg"
      />
      <h2 className="text-2xl font-bold my-4">{title}</h2>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <p className="flex-grow text-white">{feature.text}</p>
            <span className="ml-2">
              {feature.icon === "check" ? (
                <svg
                  className="w-6 h-6 text-green-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-5h2v2H9v-2zm0-6h2v4H9V7z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </span>
          </li>
        ))}
      </ul>
      <button
        onClick={handleInquireClick}
        className="w-40 py-2 mt-6 ml-14 bg-white text-black font-semibold rounded-lg hover:bg-red-300"
      >
        Inquire Now
      </button>
    </div>
  );
};