import React from 'react'
import { NavLink } from 'react-router-dom'
import { Textarea, TextInput } from "flowbite-react";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import Typed from 'react-typed';
import BulkSms from '../../assets/Bulk_Sms.png';


function EmailServices() {
  return (
     <div>
     <div className='flex min-h-0 bg-gradient-to-r from-indigo-500  to-pink-500 h-24'>
       <div className=' flex text-4xl font-bold ml-32 mt-7 gap-12 text-white'>
        <NavLink to='/allservices'><HiMiniArrowUturnLeft /></NavLink>  
         BULK SMS AND EMAIL MARKETING
      </div>
      
  </div>
  <div className="md:flex ml-32">
      <div className=' mt-9 bg-white brightness-100 shadow-2xl rounded-2xl'>
         <form className='flex max-w-md flex-col p-4 bg-white brightness-100 shadow-2xl rounded-2xl gap-4  ml-20 mt-6'>
          <div className='text-2xl items-center text-indigo-950 justify-center font-bold'>Get in touch</div>
          
          <div className="flex gap-3">
              <div className="w-1/2">
          <TextInput id="firstname" type="text" placeholder="First Name" required shadow />
           </div>
             <div className="w-1/2">
               <TextInput id="lastname" type="text" placeholder="Last Name" required shadow />
             </div>
           </div>

           <div>
            <TextInput id='phone' type='number' placeholder='Mobile Number' required shadow />
          </div>
          <div>
            <TextInput id='email2' type='email' placeholder='amrita@gmail.com' required shadow />
          </div>
          <div className="max-w-md">
           
            <Textarea id="message" placeholder="Message" required rows={4} />
          </div>
          <button className='bg-pink-500 cursor-pointer text-white mt-3 rounded-md mb-10 font-bold h-12 text-lg'>
            <NavLink to='/'>Submit</NavLink>
          </button>
        </form>
      </div>
           <div>
         <div className='  md:justify-center mt-28 ml-10 '>
            <div className='md:text-right'>
             <img src={BulkSms} alt="" className='inline' />
          </div>
      </div>
          <div className='flex mt-8 ml-40 text-opacity-100  text-fuchsia-700'>
        <div className='text-pink-500 text-2xl'>
          BULK SMS
      </div>
          <Typed
            className='pl-3 text-xl'
            strings={[
              'sending and receiving messages',
              'organizing emails with folders and labels',
              'search functionality',
              'spam filtering',
              'auto-responders',
              'and integration with calendars and contacts.',
              
            ]}
            typeSpeed={100}
            loop={true}
            backSpeed={100}
          />
        </div>

      </div>

      </div>
    </div>
  )
}

export default EmailServices
