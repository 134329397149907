import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Textarea,  TextInput, Label, Select  } from "flowbite-react";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import Typed from 'react-typed';
import webdesign from '../../assets/web_dev.jpg';



function EC_Council() {
  const [user, setUser] = useState({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      qualification: "",
      applyfor: "",
      
     });
          const navigate = useNavigate();
          //const { storeTokenInLS } = useAuth();
     //handling the input value
     const handleInput= (e) => {
      console.log(e);
      const { name, value } = e.target

      setUser({
        ...user,
        [name]: value,

      });
     }
       //handling the form submission
        const handleSubmit = async (e) => {
          e.preventDefault();
         console.log(user);
         try {
          const response = await fetch(`http://localhost:5000/api/user/councilregister`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
         });

         if(response.ok){
      //      const res_data = await response.json();
      //   console.log(" res from server", res_data )
      //  // store the token to localhost
      //   storeTokenInLS(res_data.token);
          alert("Register successfully")
          setUser({  firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      qualification: "",
      applyfor: "",
       });
      navigate("/councillogin")
         }
         console.log(response);
          
         } catch (error) {
          console.log("councillogin", error);
         }
         
      }
  return (
     <div>
     <div className='flex min-h-0 bg-gradient-to-r from-indigo-500  to-pink-500 h-24'>
       <div className=' flex text-4xl font-bold ml-32 mt-7 gap-12 text-white'>
        <NavLink to='/allservices'><HiMiniArrowUturnLeft /></NavLink>  
         EC-COUNCIL
      </div>
      
  </div>
  <div className="md:flex ml-32">
      <div className=' mt-9 bg-white brightness-100 shadow-2xl rounded-2xl'>
         <form className='flex max-w-md flex-col p-4 bg-white brightness-100 shadow-2xl rounded-2xl gap-4  ml-20 mt-6' onSubmit={handleSubmit}>
          <div className='text-2xl items-center text-indigo-950 justify-center font-bold'>Get in touch</div>         
          <div className="flex gap-3">
              <div className="w-1/2">
          <TextInput id="firstname" name='firstname' type="text" placeholder="First Name" required shadow value={user.firstname} onChange={handleInput} />
           </div>
             <div className="w-1/2">
               <TextInput id="lastname" name='lastname' type="text" placeholder="Last Name" required shadow value={user.lastname} onChange={handleInput} />
             </div>
           </div>

           <div>
            <TextInput id='phone' name='phone' type='number' placeholder='Mobile Number' required shadow value={user.phone} onChange={handleInput} />
          </div>
          <div>
            <TextInput id='email' name='email' type='email' placeholder='amrita@gmail.com' required shadow value={user.email} onChange={handleInput}/>
          </div>
          <div>
         <TextInput id="password" name='password' type="password" placeholder="password" required shadow value={user.passsword} onChange={handleInput}
           />
      </div>
          
          <div className=' mr-2'>
            <Label htmlFor='Technology' value='Your Qualification' />
          </div>
            <Select id='qualification' required className='mb-2 mr-2' 
            name= 'qualification' value={user.qualification} onChange={handleInput}>
              <option>MCA</option>
              <option>Btech</option>
              <option>BCA</option>
              <option>MBA</option>
              <option>BBA</option>
           </Select>
            <div className=' mr-2'>
    <Label htmlFor='applyfor'  value='Apply For'  />
  </div>
  <Select id='courses' required className='mb-2 mr-2' 
     name= 'applyfor' value={user.applyfor} onChange={handleInput}>
    <option>Introduction to Ethical Hacking</option>
    <option>Vulnerability Analysis</option>
    <option>SQL Injection</option>
    <option>Enumeation </option>
    <option>Evading IDS, Firewalls and Honeypots</option>
    <option>Hacking Wireless Networks</option>
    <option>Cloud Computing</option>
    <option>Footprinting and Reconnaisance</option>
    <option>Malware Threats</option>
    <option>Social Engineering  </option>
    <option>System Hacking</option>
    <option>Hacking Web Servers</option>
    <option>Hacking Mobile Platforms</option>
    <option>Cryptography</option>
    <option>Scanning Networks</option>
    <option>Sniffing</option>
    <option>Denial-of-service</option>
    <option>Session hijacking</option>
    <option>Hacking web Applications</option>
    <option>IOT Hacking</option>
  </Select>
          <div className="max-w-md">
            <Textarea id="message" placeholder="Message" required rows={4} />
          </div>
          <button className='bg-pink-500 cursor-pointer text-white mt-3 rounded-md mb-10 font-bold h-12 text-lg'>
            Submit
          </button>
        </form>
      </div>            
          <div>
         <div className='  md:justify-center md:mt-28 mt-14  '>
            <div className='md:text-right'>
             <img src={webdesign} alt="" className='inline' />
          </div>
      </div>
          <div className='flex mt-8  md:ml-40 text-opacity-100  text-fuchsia-700'>
        <div className='text-pink-500 text-2xl'>
          Ec-Council 
      </div>
          <Typed
            className='pl-3 text-xl'
            strings={[
              'gives you greate skills.',
              'has 40+ training and certification programs',
              'provides certified trainers' ,
              'has 2830+ authorized training partners',
            ]}
            typeSpeed={100}
            loop={true}
            backSpeed={100}
          />
        </div>

      </div>
      </div>
      </div>
    
  )
}

export default EC_Council
