import React,  { useState } from 'react'
import { Card } from "flowbite-react";
import { ImPointRight } from "react-icons/im";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import { NavLink } from 'react-router-dom';


import Softwaredev from '../../assets/Software-dev.jpg';
import WebsiteDev from '../../assets/mobile-banner.jpg';
import Mobiledev from '../../assets/Mobile-dev (1).jpg';
import ITConsultancy from '../../assets/BBA.jpg'
import services from '../../assets/services.jpg';
import services1 from '../../assets/MBA.jpg';


function DigitalIndeaCSC() {
  const [showMore, setShowMore] = useState(Array(17).fill(false));

  const toggleShowMore = (index) => {
    const newShowMore = [...showMore];
    newShowMore[index] = !newShowMore[index];
    setShowMore(newShowMore);
  };

  return (
    <div>
      <div className="flex min-h-0 bg-gradient-to-r from-indigo-500 to-pink-500 h-24">
        <div className="flex items-center md:text-4xl text-2xl font-bold ml-36 text-white">
          <div className="cursor-pointer">
            <NavLink to="/allservices">
              <HiMiniArrowUturnLeft />
            </NavLink>
          </div>
          <span className="ml-96">DIGITAL-INDIA-CSC-ACADEMY</span>
        </div>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-1 ml-36">
        {[
          {
            logo: Softwaredev,
            title: "ADVANCED DEPLOMA IN COMPUTER APPLICATIONS",
            details: [
              "Code:- 113",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have completed high school or equivalent from a recognized board.",
              "Subject Name:- Computer Fundamentals and Windows MS Office, Tally, Programming Language, C, C++, Java, V.B. DTP MS SQL Project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 0,
          },
          {
            logo: WebsiteDev,
            title: "ADVANCED DEPLOMA IN HARDWARE & NETWORKING",
            details: [
              "Code:- 120",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have completed high school or equivalent from a recognized board.",
              "Subject Name:- Analog Electronics & Digital Electronics Computer Fundamental and Windows Installation & Assembling Computer Architecture Network Fundamental & Security IP & Web Security project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 1,
          },
          {
            logo: Mobiledev,
            title: "CERTIFICATE IN COURSE IN IT & BPO",
            details: [
              "Code:- 130",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Basic of Computer Programming with C General & Computer Networking & Web Design & Soft and communication Skills",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 2,
          },
          {
            logo: ITConsultancy,
            title: "CERTIFICATE IN DATA ENTRY OPERATOR",
            details: [
              "Code:- 126",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 8th standard or equivalent from a recognized board.",
              "Subject Name:- Basic of Computer & System software and application software & Windows & MS Office & Typing Skills ",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 3,
          },
          {
            logo: services,
            title: "CERTIFICATE IN INTERNET & MULTIMEDIA",
            details: [
              "Code:- 122",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Windows & Fundamental of Internet / Web & Multimedia / graphic, sound and video editing. ",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 4,
          },
          {
            logo: Softwaredev,
            title: "CERTIFICATE IN PRIMARY TEACHING",
            details: [
              "Code:- 102",
              "Durations:- 6 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Principles and Approaches of Early Childhood Education & Characteristics of young Learners & Lesson Planning & Instructional Strategies & Classroom Management & Project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 5,
          },
          {
            logo: WebsiteDev,
            title: "CERTIFICATE IN YOGA TEACHER TRAINING",
            details: [
              "Code:- 452",
              "Durations:- 6 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:-Yoga Anatomy Introduction to yogic Anatomy and Physiology & Self study / Project Work. Applied Yogic Principles & Practicals & Asanas(Practical) & Pranayamas & Mudras",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 6,
          },
          {
            logo: Mobiledev,
            title: "DIPLOMA IN COMPUTER APPLICATION",
            details: [
              "Code:- 141",
              "Durations:- 6 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Fundamental of Computer and Windows & Programming C, C++, V.B., Java & MS Access / DBMS & MS Office ",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 7,
          },
          {
            logo: ITConsultancy,
            title: "DIPLOMA IN COMPUTERIZED FINANCIAL ACCOUNTING",
            details: [
              "Code:- 150",
              "Durations:- 6 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Principles of Accounting – Business Accounting & Computerized Accounting Accounting Software & Fundamental of Computer and Windows & Project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 8,
          },
          {
            logo: services,
            title: "NURSERY TEACHER TRAINING COURSE",
            details: [
              "Code:- 101",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Basics of the pre primary education & Teaching Methodology & Child Psychology & Practical: Viva Voice and Art & Crafts",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 9,
          },
          {
            logo: services1,
            title: "DIPLOMA IN INFORMATION TECHNOLOGY",
            details: [
              "Code:- 116",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Computer Fundamentals and Windows & MS Office & Tally & Programming Language, C, C++, Java, V.B. & MS SQL, DBMS & Project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 10,
          },
          {
            logo: Softwaredev,
            title: "DIPLOMA IN YOGA EDUCATION",
            details: [
              "Code:- 202",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Patanjala Yoga Sutra & Anatomy and Physiology of Yogic Practices & Yogic Therapy & Aasan, Mudras & Teaching Methods For Yogic Practices & Project ",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 11,
          },
          {
            logo: WebsiteDev,
            title: "PRIMARY TEACHER TRAINING",
            details: [
              "Code:- NCT103",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Basics of the education system & Teaching Methodology & Child Psychology & Practical: Viva Voice and Art & Crafts",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 12,
          },
          {
            logo: Mobiledev,
            title: "DIPLOMA IN FASHION DESIGN TECHNOLOGY",
            details: [
              "Code:- 106",
              "Durations:- 6 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Professional Skill (Trade Practical) & Professional Knowledge (Trade Theory) Workshop Calculation & Science & Engineering Drawing & Employability Skills",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 13,
          },
          {
            logo: ITConsultancy,
            title: "DIPLOMA IN BEAUTY THERAPY",
            details: [
              "Code:- 130",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 14,
          },
          {
            logo: services,
            title: "DIPLOMA IN COMPUTERIZED FINANCIAL ACCOUNTING",
            details: [
              "Code:- 153",
              "Durations:- 1 Year",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Principles of Accounting – Business Accounting & Computerized Accounting Accounting Software & Fundamental of Computer and Windows & Project",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 15,
          },
          {
            logo: WebsiteDev,
            title: "CERTIFICATE IN MS OFFICE",
            details: [
              "Code:- 129",
              "Durations:- 3 Month",
              "Eligibility:- Candidates must have passed in 10th standard or equivalent from a recognized board.",
              "Subject Name:- Computer Fundamenta & MS Word, MS Excel, MS Power point & MS Access & Internet Fundamental",
              "Course Medium:- Hindi and English",
              "Course Type:- Regular / Distance",
            ],
            index: 16,
          },
        ].map((course, index) => (
          <div
            key={index}
            className="md:bg-white bg-slate-50 p-4 md:mb-4 rounded"
          >
            <Card href="#" className="md:max-w-xl w-72">
              <div className="md:w-50 px-1 bg-white border-2">
                <img
                  src={course.logo}
                  alt="logo"
                  className="inline w-full h-60 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
                />
              </div>
              <h5 className="text-sm font-bold tracking-tight text-center text-gray-900 dark:text-white">
                {course.title}
                <hr className="border-slate-300 my-4" />
              </h5>
              {showMore[index] ? (
                <>
                  <div className="text-xm font-serif ml-3 -mt-4 text-slate-600">
                    {course.details.map((detail, i) => (
                      <p key={i} className="flex">
                        <ImPointRight />
                        {detail}
                      </p>
                    ))}
                    <div>
                      <button className="bg-slate-600 text-zinc-50 font-sans mt-3 rounded-md font-bold w-20 h-10 text-lg">
                        <NavLink to="/enquiry">Enquiry</NavLink>
                      </button>
                      <button className="bg-slate-700 text-zinc-50 font-sans mt-3 rounded-md ml-5 font-bold w-28 h-10 text-lg">
                        <NavLink to="/apply">Apply</NavLink>
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={() => toggleShowMore(index)}
                    className="text-blue-500"
                  >
                    Show less
                  </button>
                </>
              ) : (
                <button
                  onClick={() => toggleShowMore(index)}
                  className="text-blue-500"
                >
                  Show more
                </button>
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DigitalIndeaCSC
