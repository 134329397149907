import React, { useRef, useState } from "react";
import {
  Card,
  Textarea,
  TextInput,
  FileInput,
  Label,
  Select,
} from "flowbite-react";
import CareerDes from "../pages/CourseDes";
import emailjs from "@emailjs/browser";
import teammembers from "../../assets/team-members.png";
import WebsiteDev from "../../assets/Website-Dev.jpg";
import Mobiledev from "../../assets/Mobile-dev (1).jpg";

function Career() {
    const form = useRef();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();

      // Disable the submit button
      setButtonDisabled(true);

      // Collect form data
      const formData = new FormData(form.current);
      const data = Object.fromEntries(formData.entries());

      emailjs
        .send("service_fonuseb", "template_ary63aj", data, "cR_1YLLUsGKae2N4y")
        .then(
          () => {
            console.log("SUCCESS!");
            // Enable the button after 20 seconds
            setTimeout(() => {
              setButtonDisabled(false);
            }, 20000);
          },
          (error) => {
            console.log("FAILED...", error.text);
            // Enable the button after 20 seconds even if there is an error
            setTimeout(() => {
              setButtonDisabled(false);
            }, 20000);
          }
        );
    };

  return (
    <div className="relative">
      <div className="text-3xl font-serif ml-10 md:ml-32 py-8 text-gray-900 underline decoration-2 decoration-slate-900 underline-offset-2">
        How we do it
      </div>
      <div className="text-xl ml-10 md:ml-32 text-zinc-950 font-serif">
        Refresh Infratech is the fastest reputed and rapidly growing software IT
        company in Ranchi. we have a team of highly skilled talented and
        dedicated designers and developers have experience to complete the
        projects within the specific deadline. Being a leader software company
        we believe in providing quality service towards the clients in field of
        software we are in the top and fully committed for our services to the
        clients.
      </div>
      <div className="text-3xl font-serif ml-10 md:ml-32 text-gray-900 py-8">
        Current jobs/Internships opportunities for you
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 p-14 gap-4">
        <div className="bg-white rounded">
          <Card href="#" className="md:max-w-72 md:ml-28">
            <div className="md:w-50 bg-white border-2">
              <img
                src={teammembers}
                alt="logo"
                className="inline w-full h-60 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
              />
            </div>
            <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
              Marketing Executive
            </h5>
          </Card>
        </div>
        <div className="bg-white rounded">
          <Card href="#" className="md:max-w-72 md:ml-16">
            <div className="md:w-50 mb-3 bg-white border-2">
              <img
                src={WebsiteDev}
                alt="logo"
                className="inline w-full h-60 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
              />
            </div>
            <h5 className="text-2xl font-bold text-center tracking-tight text-gray-900 dark:text-white">
              UI/Front-End Developer
            </h5>
          </Card>
        </div>
        <div className="bg-white rounded">
          <Card href="#" className="md:max-w-72">
            <div className="md:w-50 bg-white border-2">
              <img
                src={Mobiledev}
                alt="logo"
                className="inline w-full h-60 md:w-30 md:mr-2 transition-transform transform-gpu hover:blur-0 mr-0"
              />
            </div>
            <h5 className="text-2xl font-bold tracking-tight text-center text-gray-900 dark:text-white">
              Senior .Net Web Developer
            </h5>
          </Card>
        </div>
      </div>

      <CareerDes />
      <div className="bg-white brightness-100 shadow-xl rounded-2xl p-20 md:ml-36 mt-10">
        <form
          className="max-w-lg flex flex-col gap-4"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="text-2xl text-indigo-950 font-bold">Get in touch</div>

          <div className="grid grid-cols-2 gap-3">
            <div>
              <Label htmlFor="firstname" value="First Name" />
              <TextInput
                id="firstname"
                name="firstname"
                type="text"
                placeholder="First Name"
                required
                shadow
              />
            </div>
            <div>
              <Label htmlFor="lastname" value="Last Name" />
              <TextInput
                id="lastname"
                name="lastname"
                type="text"
                placeholder="Last Name"
                required
                shadow
              />
            </div>
          </div>

          <div>
            <Label htmlFor="phone" value="Mobile Number" />
            <TextInput
              id="phone"
              name="phone"
              type="number"
              placeholder="Mobile Number"
              required
              shadow
            />
          </div>
          <div>
            <Label htmlFor="email" value="Email" />
            <TextInput
              id="email"
              name="email"
              type="email"
              placeholder="digisoultech@gmail.com"
              required
              shadow
            />
          </div>

          <div>
            <Label htmlFor="qualification" value="Qualification" />
            <Select
              id="qualification"
              required
              className="mb-2 mr-2"
              name="qualification"
            >
              <option>MCA</option>
              <option>Btech</option>
              <option>BCA</option>
              <option>MBA</option>
              <option>BBA</option>
            </Select>
          </div>

          <div>
            <Label htmlFor="address" value="Your Location" />
            <TextInput
              id="address"
              name="address"
              type="text"
              placeholder="Your Location"
              required
              shadow
            />
          </div>
          <div>
            <Label htmlFor="message" value="Message" />
            <Textarea placeholder="Message" name="message" required rows={4} />
          </div>
          <div className="flex flex-wrap items-center">
            <div className="mr-2">
              <Label htmlFor="applyfor" value="Apply For" />
            </div>
            <Select
              id="applyfor"
              required
              className="mb-2 mr-2"
              name="applyfor"
            >
              <option value="">Select</option>
              <option value="Onsite Job">Onsite Job</option>
              <option value="Remote Job">Remote Job</option>
              <option value="Internship">Internship</option>
            </Select>
            <div className="mb-2 block mr-2">
              <Label htmlFor="role" value="Select Role" />
            </div>
            <Select id="role" name="role" required className="">
              <option value="">Select</option>
              <option value="Marketing Executive">Marketing Executive</option>
              <option value="Frontend Developer">Frontend Developer</option>
              <option value="Senior .Net Developer">
                Senior .Net Developer
              </option>
            </Select>
          </div>
          <div>
            <Label
              htmlFor="file-upload-helper-text"
              value="Upload Your Resume (under 50Kb)"
            />
            <FileInput
              type="file"
              name="user_resume"
              accept=".pdf,.doc,.docx"
            />
          </div>

          <button
            type="submit"
            className={`bg-pink-500 cursor-pointer text-white mt-3 rounded-md font-bold h-12 text-lg ${
              isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isButtonDisabled}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Career;
