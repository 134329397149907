import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./ui/home/Home.jsx";
import Navbar from "./ui/home/Navbar.jsx";
import Footer from "./ui/home/Footer.jsx";
import AboutUs from "./shared/pages/AboutUs.jsx";
import Career from "./shared/pages/Career.jsx";
import ECCouncil from "./shared/pages/ECCouncil.jsx";
import Services from "./shared/pages/Services.jsx";
import Contact from "./shared/pages/Contact.jsx";
import AllServices from "./ui/dashboard/AllServices.jsx";
import AppDev from "./ui/services/AppDev.jsx";
import DataEntry from "./ui/services/DataEntry.jsx";
import DigitalIndeaCSC from "./ui/services/DigitalIndeaCSC.jsx";
import DigitalMarketing from "./ui/services/DigitalMarketing.jsx";
import EC_Council from "./ui/services/EC_Council.jsx";
import ECommerceSoul from "./ui/services/ECommerceSoul.jsx";
import EmailServices from "./ui/services/EmailServices.jsx";
import EthicalHacking from "./ui/services/EthicalHacking.jsx";
import Hosting from "./ui/services/Hosting.jsx";
import Resposnsiveweb from "./ui/services/Resposnsiveweb.jsx";
import SEOSoul from "./ui/services/SEOSoul.jsx";
import Webdevelopment from "./ui/services/Webdevelopment.jsx";
import WebsiteDesign from "./ui/services/WebsiteDesign.jsx";
import WebsiteRedesign from "./ui/services/WebsiteRedesign.jsx";
import SmsServices from "./ui/services/SmsServices.jsx";
import Apply from "./shared/pages/Apply.jsx";
import Enquiry from "./shared/pages/Enquiry.jsx";
import Internship from "./shared/pages/Internship.jsx";
import CareerDes from "./shared/pages/CourseDes.jsx";
import AllServeIndustries from "./ui/dashboard/AllServeIndustries.jsx";
import EnquiryLogin from "./shared/loginpages/EnquiryLogin.jsx";
import ApplyLogin from "./shared/loginpages/ApplyLogin.jsx";
import ECCouncilLogin from "./shared/loginpages/ECCouncilLogin.jsx";
import AdminLayout from "./shared/utils/AdminLayout.jsx";
import AdminUsers from "./shared/utils/AdminUser.jsx";
import AdminECCouncil from "./shared/utils/AdminECCouncil.jsx";
import AdminEnquiry from "./shared/utils/AdminEnquiry.jsx";
import AdminApply from "./shared/utils/AdminApply.jsx";
import Headers from "./components/Header.jsx";
import Cards from "./components/Cards.jsx";
import CardsDetails from "./components/CardsDetails.jsx";
import Checkout from "./components/Chechout.jsx";
import PaymentComponent from "../src/PaymentComponent.jsx";
import Amazon from "./shared/pages/Amazon.jsx";
import PaymentServices from "./shared/pages/PaymentServices.jsx";


const App = () => {
  return (
    <Router >
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/eccouncil" element={<ECCouncil />} />
        <Route path="/services" element={<Services />} />
        <Route path="/internship" element={<Internship />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/enquirylogin" element={<EnquiryLogin />} />
        <Route path="/applylogin" element={<ApplyLogin />} />
        <Route path="/councillogin" element={<ECCouncilLogin />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/allservices" element={<AllServices />} />
        <Route path="/allserveindustries" element={<AllServeIndustries />} />
        <Route path="/appdevelopment" element={<AppDev />} />
        <Route path="/blockchain" element={<DataEntry />} />
        <Route path="/CSC-Academy" element={<DigitalIndeaCSC />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/council" element={<EC_Council />} />
        <Route path="/ecommerce" element={<ECommerceSoul />} />
        <Route path="/emailservices" element={<EmailServices />} />
        <Route path="/ethicalhacking" element={<EthicalHacking />} />
        <Route path="/hosting" element={<Hosting />} />
        <Route path="/responsivewebsite" element={<Resposnsiveweb />} />
        <Route path="/seo" element={<SEOSoul />} />
        <Route path="/websitedevelopment" element={<Webdevelopment />} />
        <Route path="/websitedesigning" element={<WebsiteDesign />} />
        <Route path="/websiteredesign" element={<WebsiteRedesign />} />
        <Route path="/sms" element={<SmsServices />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/careerdes" element={<CareerDes />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/cardsdetails" element={<CardsDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/paymentheader/*" element={<Headers />} />
        <Route path="/paymentcomponent/*" element={<PaymentComponent />} />
        <Route path="/amazon" element={<Amazon />} />
        <Route path="/paymentservice" element={<PaymentServices />} />
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route path="users" element={<AdminUsers />} />
          <Route path="eccouncildata" element={<AdminECCouncil />} />
          <Route path="apply" element={<AdminApply />} />
          <Route path="enquiry" element={<AdminEnquiry />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
