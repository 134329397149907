import React, { useRef, useState } from "react";
import { NavLink } from 'react-router-dom'
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import emailjs from "@emailjs/browser";
import { Textarea, TextInput, Label, Select} from "flowbite-react";
import Typed from 'react-typed';

import webdesign from '../../assets/web_design.png';



function Apply() {
  const form = useRef();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Disable the submit button
    setButtonDisabled(true);

    // Collect form data
    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData.entries());

    emailjs
      .send("service_fonuseb", "template_ary63aj", data, "cR_1YLLUsGKae2N4y")
      .then(
        () => {
          console.log("SUCCESS!");
          // Enable the button after 20 seconds
          setTimeout(() => {
            setButtonDisabled(false);
          }, 20000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          // Enable the button after 20 seconds even if there is an error
          setTimeout(() => {
            setButtonDisabled(false);
          }, 20000);
        }
      );
  };
  return (
    <div>
      <div className="flex min-h-0 bg-gradient-to-r from-indigo-500 to-pink-500 h-24">
        <div className="flex items-center text-4xl font-bold ml-36 text-white">
          <div className="cursor-pointer">
            <NavLink to="/allservices">
              <HiMiniArrowUturnLeft />
            </NavLink>
          </div>
          <span className=" ml-80">APPLY FOR DIGITAL-INDIA-CSC-ACADEMY</span>
        </div>
      </div>
      <div className="flex ml-32">
        <div className=" mt-9 bg-white brightness-100 shadow-2xl rounded-2xl">
          <form
            className="flex max-w-md flex-col p-4 bg-white brightness-100 shadow-2xl rounded-2xl gap-4  ml-20 mt-6"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="text-2xl items-center text-indigo-950 justify-center font-bold">
              Get in touch
            </div>

            <div className="flex gap-3">
              <div className="w-1/2">
                <TextInput
                  id="firstname"
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  required
                  shadow
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  id="lastname"
                  name="lastname"
                  type="text"
                  placeholder="Last Name"
                  required
                  shadow
                />
              </div>
            </div>

            <div>
              <TextInput
                id="phone"
                name="phone"
                type="number"
                placeholder="Mobile Number"
                required
                shadow
              />
            </div>
            <div>
              <TextInput
                id="email"
                name="email"
                type="email"
                placeholder="amrita@gmail.com"
                required
                shadow
              />
            </div>
            <div className=" mr-2">
              <Label htmlFor="Technology" value="Your Qualification" />
            </div>
            <Select
              id="qualification"
              required
              className="mb-2 mr-2"
              name="qualification"
            >
              <option>MCA</option>
              <option>Btech</option>
              <option>BCA</option>
              <option>MBA</option>
              <option>BBA</option>
            </Select>
            <div className=" mr-2">
              <Label htmlFor="Technology" value="Apply For Courses" />
            </div>
            <Select id="courses" required className="mb-2 mr-2" name="applyfor">
              <option>Advanced Diploma In Computer Applications</option>
              <option>Advanced Diploma In Haedware & Networking</option>
              <option>Certificate In Course In IT & BPO</option>
              <option>Certificate In Data Entry Operator</option>
              <option>Certificate In Internet & Multimedia</option>
              <option>Certificate In Primary Teaching</option>
              <option>Certificate In Yoga Teacher Training</option>
              <option>Diploma In Computer Application</option>
              <option>Diploma In Computerized Financial Accounting</option>
              <option>Nursery Teacher Training Course</option>
              <option>Diploma In Information Technology</option>
              <option>Diploma In Yoga Education</option>
              <option>Primary Teacher Training</option>
              <option>Diploma In Fasion Design Technology</option>
              <option>Diploma n Beauty Therapy </option>
            </Select>
            <div>
              <Textarea
                placeholder="Message"
                name="message"
                required
                rows={4}
              />
            </div>
            <button
              type="submit"
              className={`bg-pink-500 cursor-pointer text-white mt-3 rounded-md font-bold h-12 text-lg ${
                isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </form>
        </div>
        <div>
          <div className="  md:justify-center mt-28 ml-10 ">
            <div className="md:text-right">
              <img src={webdesign} alt="" className="inline" />
            </div>
          </div>
          <div className="flex mt-8 ml-40 text-opacity-100  text-fuchsia-700">
            <div className="text-pink-500 text-4xl">Website Design</div>
            <Typed
              className="pl-3 text-3xl"
              strings={[
                "Has Numerous Components.",
                "Gives You Greate Skills.",
                "Used For Design Of Websites.",
                "Are Displayed On The Internet.",
                "Designers Have A Great Scope.",
              ]}
              typeSpeed={100}
              loop={true}
              backSpeed={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apply